import { TbBulb, TbBulbOff, TbUnlink } from "react-icons/tb";
import { Menu } from "@headlessui/react";
import { CursorClickIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID, updateAsset } from "store/reducers";
import { useIsAdmin } from "components/Restricted";
import { TransparentButton } from "components/shared/buttons";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type StorageAssetRowDropdownProps = {
  assetId: number;
  onConfigure?: () => void;
  onDelete?: () => void;
};

export const StorageAssetRowDropdown = ({ assetId, onConfigure, onDelete }: StorageAssetRowDropdownProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const canConfigureAsset = useIsAdmin();

  const toggleAvailable = async () => {
    try {
      if (!asset) return;
      await dispatch(
        updateAsset({
          ...asset,
          available: !asset.available,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "info",
          title: `Asset marked ${asset.available ? "unavailable" : "available"}`,
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      onConfigure &&
        canConfigureAsset && {
          label: "Configuration",
          icon: CursorClickIcon,
          onClick: onConfigure,
        },
      {
        label: `Make ${asset?.available ? "unavailable" : "available"}`,
        icon: asset?.available ? TbBulbOff : TbBulb,
        onClick: toggleAvailable,
      },
      onDelete && {
        label: "Remove from storage",
        icon: TbUnlink,
        onClick: onDelete,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
