import { useSelector } from "react-redux";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import AlertRules from "lib/constants/AlertRules";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createAlertRule, deleteAlertRule, selectAlertByID, selectAlertRuleByAlertID } from "store/reducers";
import { AlertEventKey, UUID } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import { PowerInput } from "components/inputs/PowerInput";

const Grid = styled.dl`
  grid-template-columns: auto 5rem;
  grid-gap: 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

type AlertRuleInputProps = {
  alertId: UUID;
  ruleKey: AlertEventKey;
};

export const AlertRuleInput = ({ alertId, ruleKey }: AlertRuleInputProps) => {
  const dispatch = useDispatch();
  const alert = useSelector(selectAlertByID(alertId));
  const entity = useSelector(selectAlertRuleByAlertID(alertId, ruleKey));
  const lookup = AlertRules.resolveItem(ruleKey);

  const handleChange = () => {
    try {
      if (!alert) return;
      const creating = !entity;
      if (creating) {
        dispatch(
          createAlertRule({
            alertId: alert.id,
            key: ruleKey,
          }),
        );
      } else {
        dispatch(
          deleteAlertRule({
            alertId: alert.id,
            id: entity.id,
          }),
        );
      }
      dispatch(
        showPopupFlag({
          appearance: "info",
          title: `Alert rule ${creating ? "enabled" : "disabled"}`,
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!lookup) {
    return null;
  }

  return (
    <Grid as={DataGrid}>
      <DataItem
        label={lookup.label}
        description={lookup.description}
        value={<PowerInput value={Boolean(entity)} onChange={handleChange} />}
      />
    </Grid>
  );
};
