import React, { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useModal } from "effects";
import { Moment } from "moment";
import Resolve from "lib/Resolve";
import { PopupFlag, showPopupFlag } from "lib/actions/userInterface";
import { CalibrationIntervals, CalibrationIntervalsOption } from "lib/constants/Calibration";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { updateAsset } from "store/reducers";
import { CalibrationTrackingMethodOption, MutateCalibrationConfigRequest, TAsset } from "types";
import { Button, SecondaryButton } from "components/Buttons";
import RadioInput from "components/Input/RadioInput";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals";
import { ActuationInterval } from "components/shared/features/calibration/CalibrationConfigureModal/ActuationInterval";
import { NextDue } from "components/shared/features/calibration/CalibrationConfigureModal/NextDue";
import { TimeInterval } from "components/shared/features/calibration/CalibrationConfigureModal/TimeInterval";
import { VSpace } from "components/shared/layouts";

dayjs.extend(utc);

const defaultCalibrationConfig: Partial<MutateCalibrationConfigRequest> = {
  methodId: undefined,
  timeInterval: undefined,
  actuationCount: 0,
  actuationInterval: 0,
  assetId: 0,
  nextDue: undefined,
};

type CalibrationConfigureFormProps = {
  asset: TAsset;
  onSuccess: () => void;
};

export const CalibrationConfigureForm = ({ asset, onSuccess }: CalibrationConfigureFormProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [submitting, isSubmitting] = useState<boolean>(false);
  const { calibrationConfig } = asset;
  const [values, setValues] = useState<Partial<MutateCalibrationConfigRequest>>(
    calibrationConfig || defaultCalibrationConfig,
  );

  const isCreating = !calibrationConfig;
  const isUpdating = !isCreating && !!values.methodId;
  const isDisabling = !isCreating && !values.methodId;

  const trackingMethods = Resolve.getCalibrationTrackingMethods();
  const animation = "animate__animated animate__fast animate__fadeIn";

  const handleSubmit = async () => {
    try {
      if (!asset) return;
      isSubmitting(true);

      const calibrationConfig: MutateCalibrationConfigRequest | undefined = isDisabling
        ? undefined
        : {
            assetId: asset.id,
            methodId: values.methodId || 1,
            timeInterval: values.timeInterval || "",
            actuationInterval: values.actuationInterval || 0,
            actuationCount: values.actuationCount || 0,
            nextDue: values.nextDue ? dayjs(values.nextDue).toISOString() : undefined,
          };

      await dispatch(
        updateAsset({
          ...asset,
          calibrationConfig,
        }),
      ).unwrap();

      let flag: PopupFlag | undefined;
      switch (true) {
        case isCreating:
          flag = {
            appearance: "success",
            title: "Calibration has been enabled",
          };
          break;
        case isUpdating:
          flag = {
            appearance: "success",
            title: "Configuration has been updated",
          };
          break;
        case isDisabling:
          flag = {
            appearance: "warning",
            title: "Calibration has been disabled",
          };
          break;
      }

      if (flag) {
        dispatch(showPopupFlag(flag));
      }

      onSuccess();
      closeModal();
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      isSubmitting(false);
    }
  };

  const handleMethodIdChange = (option: CalibrationTrackingMethodOption) => {
    setValues((prevState) => ({
      ...prevState,
      methodId: option?.value,
    }));
  };

  const handleActuationIntervalChange = (value: number) => {
    setValues((prevState) => ({
      ...prevState,
      actuationInterval: value,
    }));
  };

  const handleTimeIntervalChange = (option?: CalibrationIntervalsOption) => {
    setValues((prevState) => ({
      ...prevState,
      timeInterval: option?.value,
    }));

    if (!option) {
      // Do not continue
      return;
    }

    const unit = option.value === CalibrationIntervals.QUARTER ? CalibrationIntervals.MONTH : option.value;

    const count = option.value === CalibrationIntervals.QUARTER ? 3 : 1;

    setValues((prevState) => ({
      ...prevState,
      nextDue: dayjs().add(count, unit).format("YYYY-MM-DD"),
    }));
  };

  const handleNextDueChange = (value: Moment) => {
    setValues((prevState) => ({
      ...prevState,
      nextDue: value.format("YYYY-MM-DD"),
    }));
  };

  const renderForm = () => {
    switch (values.methodId) {
      case CalibrationTrackingMethods.ACTUATION:
        return <ActuationInterval value={values.actuationInterval} onChange={handleActuationIntervalChange} />;
      case CalibrationTrackingMethods.TIME:
        return (
          <VSpace>
            <TimeInterval value={values.timeInterval} onChange={handleTimeIntervalChange} />
            {values.timeInterval && <NextDue value={values.nextDue} onChange={handleNextDueChange} />}
          </VSpace>
        );
      case CalibrationTrackingMethods.TIME_AND_ACTUATION:
        return (
          <VSpace>
            <ActuationInterval value={values.actuationInterval} onChange={handleActuationIntervalChange} />
            <TimeInterval value={values.timeInterval} onChange={handleTimeIntervalChange} />
            {values.timeInterval && <NextDue value={values.nextDue} onChange={handleNextDueChange} />}
          </VSpace>
        );
      default:
        return null;
    }
  };

  return (
    <Modal>
      <ModalHeader title="Configure tool calibration" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Tracking method">
            <RadioInput options={trackingMethods} value={values.methodId} onChange={handleMethodIdChange} />
          </FieldGroup>
          {values.methodId && <div className={animation}>{renderForm()}</div>}
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <Button disabled={submitting} onClick={handleSubmit} color={isDisabling ? "warning" : "primary"}>
          {isCreating ? "Enable calibration" : isDisabling ? "Disable calibration" : "Save changes"}
        </Button>
        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
