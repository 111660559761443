import moment from "moment";
import Resolve from "lib/Resolve";
import { FixLater, TAssetHistoryEvent } from "types";
import StageHeader from "components/StageHeader";
import { Table, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import Trusted from "components/Trusted";
import { Anchor } from "components/Typography";
import { TextBody } from "components/shared";
import style from "../../form.module.scss";

const renderEvent = (event: TAssetHistoryEvent) => {
  return <span>{event.message || event.typeName}</span>;
};

const Actioner = ({ event }: { event: TAssetHistoryEvent }) => {
  if (!event.userId) {
    return (
      <TextBody textColor="text-gray-400" className="inline">
        Automify
      </TextBody>
    );
  }
  const name = Resolve.resolveUserFullName(event.userId);
  return (
    <Anchor to={`/configuration/users/${event.userId}`} target="_blank">
      {name}
    </Anchor>
  );
};

const When = ({ event }: { event: TAssetHistoryEvent }) => {
  const when = moment(event.dateCreated);
  const since = moment().diff(when, "hours");
  return (
    <span className={style.when}>
      {since === 0 ? when.fromNow() : when.calendar(null, { sameElse: "DD/MM/YYYY [at] h:mm A" })}
    </span>
  );
};

type AssetHistoryProps = {
  events: TAssetHistoryEvent[];
  paging: FixLater;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  handleLimitChange: (limit: number) => void;
};

export const AssetHistory = ({
  events,
  paging,
  handlePrevPage,
  handleNextPage,
  handleLimitChange,
}: AssetHistoryProps) => (
  <>
    <StageHeader title="Activity history" />
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeadCell>Event</TableHeadCell>
          <TableHeadCell isEmpty={true} />
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((event) => (
          <TableRow key={event.id}>
            <TableCell>
              <div>{renderEvent(event)}</div>
              <div className="text-gray-400">
                <When event={event} />
                {" by "}
                <Actioner event={event} />
              </div>
            </TableCell>
            <TableCell>
              {event.evidenceId ? (
                <TableLink to={`/evidences/${event.evidenceId}`} target="_blank">
                  <Trusted />
                </TableLink>
              ) : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
      onPrevPage={handlePrevPage}
      onNextPage={handleNextPage}
      paging={paging}
      onChangeLimit={handleLimitChange}
    />
  </>
);
