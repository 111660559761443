import classNames from "classnames";
import styled from "styled-components";

const getSize = (size: string): number => {
  switch (size) {
    case "small":
      return 1;
    case "medium":
      return 1.5;
    case "large":
      return 2;
    default:
      return 1.5;
  }
};

const SVG = styled.svg<{ textColor: string; size: string }>`
  color: ${({ textColor }) => textColor};
  width: ${({ size }) => getSize(size)}rem;
  height: ${({ size }) => getSize(size)}rem;
`;

const Circle = styled.circle`
  opacity: 0.25;
  stroke: currentColor;
`;

const Path = styled.path`
  opacity: 0.75;
  fill: currentColor;
`;

type LoadingProps = {
  textColor?: string;
  size?: "small" | "medium" | "large";
  className?: string;
};

const Loading = ({ textColor = "rgb(15 23 42)", size = "medium", className }: LoadingProps) => {
  return (
    <SVG
      className={classNames("ion-spin", className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      size={size}
      textColor={textColor}
    >
      <Circle cx="12" cy="12" r="10" strokeWidth="4" />
      <Path d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </SVG>
  );
};

export default Loading;
