import React, { FormEvent, useState } from "react";
import { useModal } from "effects";
import StorageModels from "lib/constants/StorageModels";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createStorage } from "store/reducers";
import { CreateStorageRequest, FixLater, TStorage } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectStorageModelInput from "components/inputs/SelectStorageModelInput";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "components/shared/features/storages/modals/CreateStorageModal/modal.module.scss";

type CreateStorageModalProps = {
  onSuccess?: (value: TStorage) => void;
};

export const CreateStorageModal = ({ onSuccess }: CreateStorageModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateStorageRequest>({
    type: "toolbox",
    name: "",
    description: "",
    model: "",
    drawers: [],
  });
  const [model, setModel] = useState<FixLater>();
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name: string, value: string) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent) => {
    isSubmitting(true);
    try {
      event.preventDefault();

      // Set the correct number of drawers for the Storage model
      const storageModel = StorageModels.resolveItem(model);
      values.drawers = new Array(storageModel.drawerCount);
      for (let i = 0; i < storageModel.drawerCount; i++) {
        values.drawers[i] = {
          name: "",
          value: i + 1,
          order: i,
        };
      }

      const newStorage = await dispatch(
        createStorage({
          ...values,
          model: model?.value,
        }),
      ).unwrap();
      onSuccess && onSuccess(newStorage);
      closeModal();
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = values.name && model;

  return (
    <form onSubmit={handleSubmit}>
      <Modal className={style.modal}>
        <ModalHeader title="Create a toolbox" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter toolbox name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Type">
              <SelectStorageModelInput name="model" value={model} onChange={(_, value) => setModel(value)} />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={!valid || submitting}>
            Create toolbox
          </PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
