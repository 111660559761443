import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteKeycard, fetchKeycardById, selectKeycardByID, updateKeycard } from "store/reducers";
import { FixLater } from "types";
import { SecondaryButton, WarningButton, DangerButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { TextInput } from "components/Input";
import { Header, Paragraph } from "components/Typography";
import { PowerInput } from "components/inputs/PowerInput";
import { KeycardAssignment, KeycardPinCodeInput, PageLoading, VSpace } from "components/shared";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import PageStage from "components/stages/PageStage";

export const ListKeycardPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const keycardId = params.keycardID as string;
  const { openModal } = useModal();
  const data = useSelector(selectKeycardByID(keycardId));

  useEffect(() => {
    if (!data) {
      dispatch(fetchKeycardById(keycardId));
    }
  }, [keycardId, data]);

  const saveChanges = (name: string) => {
    return async (value: FixLater) => {
      try {
        await dispatch(
          updateKeycard({
            ...data,
            [name]: value,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Changes saved",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const removeKeycard = () => {
    openModal(
      <ConfirmModal
        actionText="Remove keycard"
        description="Are you sure you want to delete this keycard? Doing this is permanent."
        onConfirm={async () => {
          try {
            await dispatch(deleteKeycard(keycardId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Keycard was removed",
              }),
            );
            navigate(`/configuration/keycards`, { replace: true });
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const toggleRevoked = () => {
    const { revoked } = data;
    const modalOptions = revoked
      ? {
          description:
            "Are you sure you want to activate this keycard? This will immediately allow the keycard being used to access toolboxes.",
          actionText: "Activate keycard",
        }
      : {
          description:
            "Are you sure you want to revoke this keycard? This will immediately prevent the keycard being used to access toolboxes.",
          actionText: "Revoke keycard",
        };
    openModal(
      <ConfirmModal
        actionText={modalOptions.actionText}
        description={modalOptions.description}
        onConfirm={async () => {
          try {
            await dispatch(
              updateKeycard({
                ...data,
                revoked: !revoked,
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: revoked ? "Keycard activated" : "Keycard revoked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const togglePinCode = async () => {
    try {
      await dispatch(
        updateKeycard({
          ...data,
          pinCodeEnabled: !data.pinCodeEnabled,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: `Pin code was ${data.pinCodeEnabled ? "disabled" : "enabled"}`,
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!data) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <Header canEdit={true} placeholder="Enter keycard name" value={data.name} onConfirm={saveChanges("name")} />
      <Paragraph
        canEdit
        size="large"
        placeholder="Enter keycard description"
        value={data.description}
        onConfirm={saveChanges("description")}
      />
      <DataGrid className="gap-y-4">
        <DataItem
          label="ID"
          value={
            <TextInput
              input={{
                name: "id",
                placeholder: "Enter keycard ID",
              }}
              value={data.id}
              onChange={saveChanges("id")}
            />
          }
        />
        <DataItem
          label="Assignment"
          value={
            <DataItemGroup>
              <KeycardAssignment keycardId={keycardId} />
            </DataItemGroup>
          }
        />
        <DataItem
          label="Pin code"
          value={
            data.pinCodeEnabled ? (
              <VSpace>
                <PowerInput value={data.pinCodeEnabled} onChange={togglePinCode} />
                <DataItemGroup>
                  <KeycardPinCodeInput keycardId={keycardId} />
                </DataItemGroup>
              </VSpace>
            ) : (
              <PowerInput value={data.pinCodeEnabled} onChange={togglePinCode} />
            )
          }
        />
        <DataItem
          label="Status"
          value={
            data.revoked ? (
              <SecondaryButton active onClick={toggleRevoked}>
                Activate keycard
              </SecondaryButton>
            ) : (
              <WarningButton onClick={toggleRevoked}>Revoke keycard</WarningButton>
            )
          }
        />
        <DataItem label="Remove keycard" value={<DangerButton onClick={removeKeycard}>Remove keycard</DangerButton>} />
      </DataGrid>
    </PageStage>
  );
};
