import { useEffect, useMemo, useState } from "react";
import { RiSignalTowerFill } from "react-icons/ri";
import cuid from "cuid";
import { useModal } from "effects";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID, selectStorageByID } from "store/reducers";
import { updateStorageAsset } from "store/reducers/assets/storageAssets.thunks";
import { FixLater, TAsset, TStorageAsset, TStorageDrawer } from "types";
import { FileInput, NumberInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { SecondaryButton, TrashButton } from "components/shared/buttons";
import { ScanToolSensorModal } from "components/shared/features/storages/modals/ScanToolSensorModal/ScanToolSensorModal";
import { DrawerSVG } from "components/shared/features/svgs";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { HSpace, VSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";
import { Heading, TextBody } from "components/shared/typography";
import { SensorSettingsForm, SVGContainer } from "../ConfigureDrawerLayoutModal/ConfigureDrawerLayoutModal";

const Form = styled.form`
  width: 100vw;
  justify-content: center;
  display: flex;
`;

const StyledModal = styled(Modal)`
  width: 100%;
  min-width: unset;

  @media only screen and (min-width: 768px) {
    max-width: 80vw;
  }
`;

const Content = styled(ModalContent)`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

type ConfigureStorageAssetLayoutModalProps = {
  storageId: string;
  assetId: number;
  drawer: TStorageDrawer;
  drawerAssets?: TAsset[];
  onSuccess?: (svgPath: string) => void;
};

export const ConfigureStorageAssetLayoutModal = ({
  storageId,
  assetId,
  drawer,
  drawerAssets = [],
  onSuccess,
}: ConfigureStorageAssetLayoutModalProps) => {
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const storage = useSelector(selectStorageByID(storageId));
  const storageAsset = asset?.storageConfig;
  const [values, setValues] = useState({
    viewBox: "",
    irSensorNumber: storageAsset?.irSensorNumber,
    irSensorThreshold: storageAsset?.irSensorThreshold,
    irSensorBoardId: storageAsset?.irSensorBoardId,
  });
  const [files, setFiles] = useState([]);
  const [fullSvg, setFullSvg] = useState<string>();
  const [shapes, setShapes] = useState([]);
  const [assetShape, setAssetShape] = useState<FixLater>(null);
  const [groupShape, setGroupShape] = useState(null);
  const drawerShapes = useMemo(() => {
    return shapes.map((shape: FixLater): FixLater => {
      const asset = drawerAssets.find((drawerAsset: FixLater) => shape.path === drawerAsset.storageConfig?.svgPath);
      return {
        ...shape,
        located: asset && asset.id !== assetId,
        asset,
        pulse: asset?.id === assetId,
      };
    });
  }, [shapes]);

  const drawerShape = useMemo(() => {
    return shapes.find((shape: FixLater) => shape.path === drawer.svgPath);
  }, [shapes]);
  const valid = fullSvg && assetShape;

  useEffect(() => {
    if (!storageAsset) return;
    if (!fullSvg) return;
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(fullSvg, "image/svg+xml");
    const svgElement = svgDoc.querySelector("svg");
    if (!svgElement) {
      handlePopupFlagError(null, "No SVG element found in the file");
      return;
    }

    // Pull out the SVG height, width, and viewport
    const viewBox = svgElement.getAttribute("viewBox");
    setValues({
      ...values,
      viewBox: viewBox || "0 0 1000 1000",
    });

    // Now extract all the path shapes.
    // @ts-ignore
    setGroupShape(svgDoc.querySelector("g") || null);

    // @ts-ignore
    const shapes: FixLater = [...svgDoc.querySelectorAll("path")].map((shape) => {
      const pathLength = shape.getTotalLength();
      return {
        id: cuid(),
        path: shape.getAttribute("d"),
        pathLength,
      };
    });
    setShapes(shapes);

    if (storageAsset.svgPath) {
      const defaultShape = shapes.find((shape: FixLater) => shape.path === storageAsset.svgPath);
      if (defaultShape) {
        setAssetShape(defaultShape);
      }
    }
  }, [storageAsset, fullSvg]);

  useEffect(() => {
    if (drawer.fullSvg) {
      setFullSvg(drawer.fullSvg);
    }
  }, []);

  const handleUpload = async (_: string, files: FixLater) => {
    try {
      setFiles(files);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const svgText: FixLater = reader.result;
        setFullSvg(svgText);
      };
      reader.readAsText(file);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const saveSettings = async (params: TStorageAsset) => {
    try {
      await dispatch(updateStorageAsset(params)).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Configuration saved",
        }),
      );
    } catch (err) {
      console.log(err);
      handlePopupFlagError(err);
    }
  };

  const handleSubmit = async (event: FixLater) => {
    event.preventDefault();
    if (!storageAsset) return;
    const newStorageAsset = {
      ...storageAsset,
      svgPath: assetShape.path,
      irSensorNumber: values.irSensorNumber,
      irSensorThreshold: values.irSensorThreshold,
      irSensorBoardId: values.irSensorBoardId,
    };
    await saveSettings(newStorageAsset);
    closeModal();
    onSuccess && onSuccess(assetShape.path);
  };

  const handleNumberChange = (name: string, value: FixLater) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    openModal(
      <ConfirmModal
        actionText="Clear sensor settings"
        description="Are you sure you want to discard the sensor settings?"
        onConfirm={async () => {
          setValues((prevState) => ({
            ...prevState,
            irSensorNumber: null,
            irSensorThreshold: null,
            irSensorBoardId: null,
          }));
        }}
      />,
    );
  };

  const handleShapeClick = (shape: FixLater) => {
    setAssetShape(shape);
  };

  const handleSampleSensor = () => {
    if (!asset) return;
    if (!storageAsset) return;
    openModal(
      <ScanToolSensorModal
        storageId={storageId}
        assetId={asset.guid}
        onSuccess={async (settings) => {
          setValues((prevState) => ({
            ...prevState,
            irSensorBoardId: settings.irSensorBoardId,
            irSensorNumber: settings.irSensorNumber,
            irSensorThreshold: settings.irSensorThreshold,
          }));
          const newStorageAsset = {
            ...storageAsset,
            svgPath: assetShape.path,
            irSensorNumber: settings.irSensorNumber,
            irSensorThreshold: settings.irSensorThreshold,
            irSensorBoardId: settings.irSensorBoardId,
          };
          await saveSettings(newStorageAsset);
        }}
      />,
    );
  };

  if (!storageAsset) return null;

  const hasValues = typeof values.irSensorBoardId === "number" || typeof values.irSensorNumber === "number";
  const canSampleThreshold =
    storage?.online && Number(values.irSensorBoardId) >= 1 && Number(values.irSensorNumber) >= 0;
  return (
    <Form onSubmit={handleSubmit}>
      <StyledModal>
        <ModalHeader title="Tool configuration">
          <TextBody textColor="text-gray-500">Configure the tool location and sensor settings.</TextBody>
        </ModalHeader>
        <Content>
          <HSpace className="flex-1">
            <SVGContainer>
              {shapes.length ? (
                <DrawerSVG
                  viewBox={values.viewBox}
                  shapes={drawerShapes}
                  value={[assetShape?.id]}
                  drawerShape={drawerShape}
                  groupShape={groupShape}
                  onClick={handleShapeClick}
                />
              ) : (
                <FileInput
                  input={{
                    name: "file",
                    placeholder: "Upload a SVG file or drag and drop",
                  }}
                  value={files}
                  onChange={handleUpload}
                />
              )}
            </SVGContainer>
          </HSpace>
          <VSpace>
            <SensorSettingsForm>
              <HSpace className="justify-between align-center">
                <Heading level="h3">Sensor settings</Heading>
                {hasValues && <TrashButton onClick={handleClear}>Clear config</TrashButton>}
              </HSpace>
              {hasValues ? (
                <>
                  <FieldGroup label="Sensor board">
                    <NumberInput
                      input={{
                        name: "irSensorBoardId",
                        step: "1",
                        min: 0,
                        placeholder: "Enter sensor board",
                      }}
                      value={values.irSensorBoardId}
                      onChange={handleNumberChange}
                    />
                  </FieldGroup>
                  <FieldGroup label="Sensor number">
                    <NumberInput
                      input={{
                        name: "irSensorNumber",
                        step: "1",
                        min: 0,
                        placeholder: "Enter sensor number",
                      }}
                      value={values.irSensorNumber}
                      onChange={handleNumberChange}
                    />
                  </FieldGroup>
                  <FieldGroup label="Sensor threshold">
                    <VSpace gap="gap-2">
                      <SecondaryButton
                        leadingIcon={RiSignalTowerFill}
                        disabled={!canSampleThreshold}
                        onClick={handleSampleSensor}
                      >
                        Scan threshold
                      </SecondaryButton>
                      <NumberInput
                        input={{
                          name: "irSensorThreshold",
                          step: "1",
                          min: 0,
                          placeholder: "Enter sensor threshold",
                        }}
                        value={values.irSensorThreshold}
                        onChange={handleNumberChange}
                      />
                    </VSpace>
                  </FieldGroup>
                </>
              ) : (
                <div>
                  <SecondaryButton
                    disabled={!storage?.online}
                    leadingIcon={RiSignalTowerFill}
                    onClick={handleSampleSensor}
                  >
                    Scan automatically
                  </SecondaryButton>
                </div>
              )}
            </SensorSettingsForm>
            <ModalFooter>
              <HSpace>
                <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
                <ModalPrimaryActionButton type="submit" disabled={!valid} onClick={handleSubmit}>
                  Save changes
                </ModalPrimaryActionButton>
              </HSpace>
            </ModalFooter>
          </VSpace>
        </Content>
      </StyledModal>
    </Form>
  );
};
