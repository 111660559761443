import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import { createStorageViewer, fetchStorageById, selectStorageByID } from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import StorageActivity from "pages/storages/ListStoragePage/components/StorageAccessHistory";
import StorageInventoryPage from "pages/storages/ListStoragePage/components/StorageInventoryPage/StorageInventoryPage";
import { StorageAccessControlPage } from "pages/storages/StorageAccessControlPage/StorageAccessControlPage";
import { StorageSettingsPage } from "pages/storages/StorageSettingsPage/StorageSettingsPage";

export const ListStoragePage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { storageID } = useParams();
  const entity = useSelector(selectStorageByID(storageID || ""));

  useEffect(() => {
    if (!storageID) return;

    // Always fetch the storage using a direct API request because it returns more data than the information
    // returned by the list storages endpoint.
    dispatch(fetchStorageById(storageID));
    dispatch(createStorageViewer(storageID));
  }, [storageID]);

  if (!entity || !storageID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="inventory" element={<StorageInventoryPage />} />
          <Route path="access_control" element={<StorageAccessControlPage />} />
          <Route path="activity" element={<StorageActivity storage={entity} />} />
          <Route path="settings" element={<StorageSettingsPage storage={entity} />} />
          <Route path="" element={<Navigate replace to={`${url}/inventory`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
