//@ts-nocheck
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { requestTaskLists } from "lib/actions/tasks";
import { getProjectByID, getProjectTaskListIDsByProjectID } from "lib/reducers/projects.selectors";
import { getTaskLists } from "lib/reducers/taskLists.selectors";
import { Text } from "components/Typography";
import { ProjectKanbanItemProgress, ProjectPriorityPill } from "components/shared";
import styles from "./ProjectKanbanItem.module.scss";

export const ProjectKanbanItem = ({ projectID, onClick }) => {
  const dispatch = useDispatch();
  const project = useSelector(getProjectByID(projectID));
  const taskListIDs = useSelector(getProjectTaskListIDsByProjectID(projectID));
  const taskLists = useSelector(getTaskLists);
  const dateDue = useMemo(() => {
    if (!project || !project.dateDue) {
      return null;
    }
    const due = moment(project.dateDue);
    const today = moment(new Date());
    if (due.isBefore(today)) {
      return null;
    }
    return due;
  }, [project]);

  useEffect(() => {
    const missing = taskListIDs.filter((id) => !taskLists[id]);
    if (missing.length) {
      dispatch(requestTaskLists({ id: missing }));
    }
  }, [taskListIDs.length]);

  return (
    project && (
      <div className={styles.ProjectKanbanItem} onClick={onClick}>
        <div className={styles.header}>
          <Text muted className={styles.code}>
            JOB-{String(project.id).padStart(2, "00")}
          </Text>
          <div className={styles.title}>{project.name}</div>
        </div>
        {!!taskListIDs.length && (
          <div className={styles.progress}>
            <ProjectKanbanItemProgress projectID={projectID} />
          </div>
        )}
        <div className={styles.statusRow}>
          <ProjectPriorityPill projectID={projectID} />
          {dateDue && (
            <Text muted className={styles.code}>
              Due in {dateDue.toNow(true)}
            </Text>
          )}
        </div>
      </div>
    )
  );
};
