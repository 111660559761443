import { useModal } from "effects";
import { DangerButton } from "components/Buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";

type AlertRemoveButtonProps = {
  onClick: () => void;
};

export const AlertRemoveButton = ({ onClick }: AlertRemoveButtonProps) => {
  const { openModal } = useModal();

  const handleRemoveAlert = () => {
    openModal(
      <ConfirmModal
        actionText="Remove alert"
        description="Are you sure you want to delete this alert? Doing this is permanent."
        onConfirm={onClick}
      />,
    );
  };

  return <DangerButton onClick={handleRemoveAlert}>Remove alert</DangerButton>;
};
