import { Menu } from "@headlessui/react";
import { TrashIcon, DotsVerticalIcon, PlayIcon, BanIcon, LockOpenIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteKeycard, selectKeycardByID, selectUserKeycardByID, updateKeycard } from "store/reducers";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type KeycardTableRowDropdownProps = {
  keycardId: string;
  onDelete?: () => void;
};

export const KeycardTableRowDropdown = ({ keycardId, onDelete }: KeycardTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const keycard = useSelector(selectKeycardByID(keycardId));
  const userKeycard = useSelector(selectUserKeycardByID(keycardId));

  const removeKeycard = () => {
    openModal(
      <ConfirmModal
        actionText="Remove keycard"
        description="Are you sure you want to delete this keycard? Doing this is permanent."
        onConfirm={async () => {
          try {
            await dispatch(deleteKeycard(keycardId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Keycard was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const toggleRevoked = () => {
    const { revoked } = keycard;
    const modalOptions = revoked
      ? {
          description:
            "Are you sure you want to activate this keycard? This will immediately allow the keycard being used to access toolboxes.",
          actionText: "Activate keycard",
        }
      : {
          description:
            "Are you sure you want to revoke this keycard? This will immediately prevent the keycard being used to access toolboxes.",
          actionText: "Revoke keycard",
        };
    openModal(
      <ConfirmModal
        actionText={modalOptions.actionText}
        description={modalOptions.description}
        onConfirm={async () => {
          try {
            await dispatch(
              updateKeycard({
                ...keycard,
                revoked: !revoked,
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: revoked ? "Keycard activated" : "Keycard revoked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      {
        label: keycard.revoked ? "Activate" : "Revoke",
        icon: keycard.revoked ? PlayIcon : BanIcon,
        onClick: toggleRevoked,
      },
      userKeycard && {
        label: "Un-assign",
        icon: LockOpenIcon,
      },
    ],
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: removeKeycard,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
