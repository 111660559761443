import { Menu } from "@headlessui/react";
import { TrashIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { deleteAlert } from "store/reducers";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type TrackingAlertsTableRowDropdownProps = {
  alertId: string;
  onDelete?: () => void;
};

export const TrackingAlertsTableRowDropdown = ({ alertId, onDelete }: TrackingAlertsTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const removeAlert = () => {
    openModal(
      <ConfirmModal
        actionText="Remove tracking alert"
        description="Are you sure you want to delete this alert? Doing this is permanent."
        onConfirm={async () => {
          try {
            await dispatch(deleteAlert(alertId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Tracking alert was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: removeAlert,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
