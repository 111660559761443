//@ts-nocheck
import React from "react";
import Confetti from "react-dom-confetti";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import style from "./icon.module.scss";

const IconWithConfetti = ({ active, config, ...otherProps }) => {
  return (
    <div className={style.confettiContainer}>
      <Confetti active={active} config={config} className={style.confetti} />
      <Icon {...otherProps} />
    </div>
  );
};

IconWithConfetti.defaultProps = {
  config: {
    angle: 90,
    spread: 160,
    startVelocity: 18,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 2200,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "800px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  },
};

IconWithConfetti.propTypes = {
  active: PropTypes.bool,
  config: PropTypes.object,
  className: PropTypes.string,
};

export default IconWithConfetti;
