import { forwardRef } from "react";
import classNames from "classnames";
import { Button, GenericButtonProps } from "components/shared/buttons";

export type SecondaryButtonProps = GenericButtonProps & {};

export const SecondaryButton = forwardRef(({ className, ...otherProps }: SecondaryButtonProps, ref: any) => {
  return (
    <Button
      backgroundColor="bg-white"
      textColor="text-gray-700"
      backgroundHover="hover:bg-gray-50"
      ringFocusColor="focus:ring-gray-300"
      borderColor="border-gray-300"
      shadow="shadow-sm"
      className={classNames(className, "disabled:bg-gray-50")}
      {...otherProps}
      ref={ref}
    />
  );
});
