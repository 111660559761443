import { useEffect } from "react";
import classNames from "classnames";
import Resolve from "lib/Resolve";
import { useDispatch, useSelector } from "store";
import { fetchAssetById, selectAssetByID } from "store/reducers";
import { TableLink } from "components/Table/TableLink/TableLink";

type AssetListItemProps = {
  assetId: number;
  onClick: () => void;
  className?: string;
};

export const AssetListItem = ({ assetId, onClick, className }: AssetListItemProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectAssetByID(assetId));
  const assigned = entity?.assignments.length !== 0;

  useEffect(() => {
    if (!entity) {
      dispatch(fetchAssetById(assetId));
    }
  }, [assetId, entity]);

  if (!entity) {
    return null;
  }

  return (
    <li onClick={onClick} className={className}>
      <div className="group relative flex items-center py-6 px-5">
        <div className="-m-1 block flex-1 p-1">
          <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
          <div className="relative flex min-w-0 flex-1 items-center">
            <span className="relative inline-block flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full object-cover"
                src={entity.catalogProduct?.imageUrl}
                alt={entity.catalogProduct?.name}
              />
              <span
                className={classNames(
                  assigned ? "bg-red-400" : "bg-green-500",
                  "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white",
                )}
                aria-hidden="true"
              />
            </span>
            <div className="ml-4 flex-1 pr-4">
              <p className="whitespace-normal font-medium text-gray-900">{entity.catalogProduct?.name}</p>
              {entity.assignments.length !== 0 ? (
                <p className="flex items-center text-sm text-gray-500">
                  Currently assigned to
                  {entity.assignments.map((assignment, index) => (
                    <>
                      <TableLink to={`/configuration/users/${assignment.userId}`} target="_blank">
                        {Resolve.resolveUserFullName(assignment.userId)}
                      </TableLink>
                      {index < entity.assignments.length - 1 ? ", " : "."}
                    </>
                  ))}
                </p>
              ) : (
                <p className="text-sm text-gray-500">{entity.serialNumber}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
