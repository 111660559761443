import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchStorages, runDeviceRFIDTagsScan } from "store/reducers/storages/storages.thunks";
import { TStorage, UUID } from "types";

const selectState = (state: RootStore) => state.toolStorages;

export const selectStorages = createSelector(selectState, (state) => state.data);

export const selectStoragesLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchStorages.pending.type,
);

export const selectStoragesList = createSelector(selectStorages, (data) => Object.values(data));

export const selectStoragesPaging = createSelector(selectState, ({ paging }) => {
  return paging;
});

export const selectStoragesPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectStorageByID = (id: UUID) => createSelector(selectStorages, (data): TStorage | undefined => data[id]);

export const isRunningDeviceRFIDTagsScanning = createSelector(
  selectState,
  ({ loading }) => loading === runDeviceRFIDTagsScan.pending.type,
);
