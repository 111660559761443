import { AppearanceTypes } from "@atlaskit/flag";
import cuid from "cuid";
import * as type from "./actionTypes";

export type PopupFlag = {
  appearance: AppearanceTypes;
  title: string;
  description?: string;
  id?: string;
};

export function showPopupFlag(flag: PopupFlag, timeout = 5000) {
  return {
    type: type.SHOW_POPUP_FLAG,
    timeout,
    payload: {
      ...flag,
      id: flag.id || cuid(),
    },
  };
}

export function dismissPopupFlag(flagID: string) {
  return {
    type: type.DISMISS_POPUP_FLAG,
    payload: flagID,
  };
}

/**
 * Set the page sidebar
 * @param {boolean} isVisible
 * @returns {{type: string}}
 */
export function setPageSidebar(isVisible = false) {
  return {
    type: type.SET_PAGE_SIDEBAR,
    payload: isVisible,
  };
}
