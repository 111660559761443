import React, { useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { receiveAsset } from "lib/actions/assets";
import { showPopupFlag } from "lib/actions/userInterface";
import Assets from "lib/api/assets";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchCaseScannerByStorageId,
  fetchStorageById,
  fetchToolRoomByStorageId,
  fetchToolStoreByStorageId,
  selectAssetByID,
  selectCaseScannerByStorageID,
  selectStorageByID,
  selectToolRoomByStorageID,
  selectToolStoreByStorageID,
  updateAsset,
} from "store/reducers";
import { TLocation, TStorageAsset } from "types";
import Avatar from "components/Avatar";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import Loading from "components/Loading";
import { Restrict, useRoleHasCapability } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import { Anchor, Paragraph } from "components/Typography";
import Text from "components/Typography/Text";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { VSpace } from "components/shared";

type AssetStorageConfigProps = {
  storageConfig: TStorageAsset;
};

const AssetStorageConfig = ({ storageConfig }: AssetStorageConfigProps) => {
  const dispatch = useDispatch();
  const storage = useSelector(selectStorageByID(storageConfig.storageId));
  const toolStore = useSelector(selectToolStoreByStorageID(storageConfig.storageId));
  const toolRoom = useSelector(selectToolRoomByStorageID(storageConfig.storageId));
  const caseScanner = useSelector(selectCaseScannerByStorageID(storageConfig.storageId));

  useEffect(() => {
    if (!storage) {
      dispatch(fetchStorageById(storageConfig.storageId));
    }
    if (storage?.type === "store" && !toolStore) {
      dispatch(fetchToolStoreByStorageId(storageConfig.storageId));
    }
    if (storage?.type === "tool_room" && !toolRoom) {
      dispatch(fetchToolRoomByStorageId(storageConfig.storageId));
    }
    if (storage?.type === "case_scanner" && !caseScanner) {
      dispatch(fetchCaseScannerByStorageId(storageConfig.storageId));
    }
  }, [storageConfig.storageId]);

  const render = () => {
    if (!storage) return null;

    const drawerName = storage.drawers.find(({ value }) => storageConfig.drawerNumber === value);
    if (!storage) {
      return;
    }

    const formatDeviceTypeName = (): string => {
      switch (storage?.type) {
        case "toolbox":
          return "Toolbox";
        case "store":
          return "Tool store";
        case "tool_room":
          return "Tool room";
        case "case_scanner":
          return "Case scanner";
        default:
          return "";
      }
    };

    const formatStorageUrl = (): string => {
      switch (storage?.type) {
        case "toolbox":
          return `/apps/storage/toolboxes/${storageConfig.storageId}`;
        case "store":
          return `/apps/storage/stores/${toolStore?.id}`;
        case "tool_room":
          return `/apps/storage/tool_rooms/${toolRoom?.id}`;
        case "case_scanner":
          return `/apps/storage/case_scanners/${caseScanner?.id}`;
        default:
          return "";
      }
    };

    return (
      <VSpace gap="gap-2">
        <FieldGroup label={formatDeviceTypeName()}>
          <Anchor to={formatStorageUrl()}>{storage.name}</Anchor>
        </FieldGroup>
        {storage.type === "toolbox" && (
          <FieldGroup label="Drawer">
            {drawerName && drawerName.name ? drawerName.name : `Drawer ${storageConfig.drawerNumber}`}
          </FieldGroup>
        )}
      </VSpace>
    );
  };

  return (
    <Restrict capability={Capabilities.STORAGE_READ}>
      <DataItemGroup>{storage ? render() : <Loading size="medium" />}</DataItemGroup>
    </Restrict>
  );
};

type AssetDetailsProps = {
  assetId: number;
};

const AssetDetails = ({ assetId }: AssetDetailsProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const canEdit = useRoleHasCapability(Capabilities.ASSET_UPDATE);
  const [qrCode, setQRCode] = useState<QRCodeStyling>();
  const [QRImageUrl, setQRImageUrl] = useState<string>();

  useEffect(() => {
    const generateQRImage = async () => {
      const qrCode = new QRCodeStyling({
        width: 512,
        height: 512,
        type: "svg",
        data: `${window.location.origin}/apps/tracking/assets/${assetId}`,
        image: "/images/qr-logo.png",
        margin: 16,
        qrOptions: {
          errorCorrectionLevel: "H",
        },
        dotsOptions: {
          color: "#854dff",
          type: "extra-rounded",
        },
        backgroundOptions: {
          color: "transparent",
        },
      });
      setQRCode(qrCode);

      const blob = await qrCode.getRawData("png");
      const reader = new FileReader();
      reader.onloadend = () => {
        setQRImageUrl(reader.result as string);
      };

      if (!blob) return;
      reader.readAsDataURL(blob);
    };
    generateQRImage();
  }, []);

  const updateSerialNumber = async (value: string) => {
    if (!asset) {
      return;
    }
    try {
      const newAsset = await Assets.updateAsset(assetId, {
        ...asset,
        serialNumber: value,
      });
      dispatch(receiveAsset(newAsset));
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Serial number updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const updateDescription = async (value: string) => {
    if (!asset) {
      return;
    }
    try {
      const newAsset = await Assets.updateAsset(assetId, {
        ...asset,
        description: value,
      });
      dispatch(receiveAsset(newAsset));
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Description updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const updateLocation = async (name: string, value: TLocation) => {
    if (!asset) {
      return;
    }
    try {
      await dispatch(
        updateAsset({
          ...asset,
          locationId: value?.id,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Location updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const downloadQRCode = async () => {
    if (!qrCode || !asset) {
      return;
    }
    await qrCode.download({
      name: `Automify QR code - ${asset.catalogProduct?.name}_${asset.id}`,
      extension: "png",
    });
  };

  if (!asset) {
    return <Loading />;
  }

  const { catalogProduct } = asset;
  return (
    <>
      <StageHeader title="Tool information" />
      <StageSection isFirst>
        <DataGrid>
          <DataItem
            label="QR code"
            value={<Avatar image={QRImageUrl} alt="" hoverIcon="/images/icons/download.svg" onClick={downloadQRCode} />}
          />
          <DataItem
            label="Information"
            value={
              <DataItemGroup>
                <DataItem label="Description">
                  <Paragraph
                    value={asset.description}
                    placeholder="Enter a description"
                    canEdit={canEdit}
                    onConfirm={updateDescription}
                    size="large"
                  />
                </DataItem>
                <DataItem label="Serial number">
                  <Text
                    value={asset.serialNumber}
                    placeholder="Enter serial number"
                    canEdit={canEdit}
                    onConfirm={updateSerialNumber}
                  />
                </DataItem>
                <DataItem label="Location">
                  <LocationSelector
                    appearance="normal"
                    name="locationId"
                    value={asset.locationId}
                    onChange={updateLocation}
                    canEdit={canEdit}
                  />
                </DataItem>
              </DataItemGroup>
            }
          />
          {asset.storageConfig && (
            <DataItem label="Storage" value={<AssetStorageConfig storageConfig={asset.storageConfig} />} />
          )}
          {catalogProduct && (
            <DataItem
              label="Product information"
              value={
                <DataItemGroup>
                  <VSpace gap="gap-2">
                    <FieldGroup label="Product name" value={catalogProduct.name} />
                    <FieldGroup label="Product number" value={catalogProduct.mpn} />
                    <FieldGroup label="Description" value={catalogProduct.description} />
                  </VSpace>
                </DataItemGroup>
              }
            />
          )}
        </DataGrid>
      </StageSection>
    </>
  );
};

export default AssetDetails;
