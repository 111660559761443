import { ComponentProps } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import styled from "styled-components";

type TrashButtonProps = ComponentProps<"span">;

export const TrashButton = styled((props: TrashButtonProps) => {
  return (
    <span {...props}>
      <HiOutlineTrash />
    </span>
  );
})`
  font-size: 1.25rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    transition: background-color ease 50ms;
    background-color: rgba(208, 215, 222, 0.32);
  }
`;
