//@ts-nocheck
import { TAsset, TAssetAssignment } from "types";
import Api from "./api";

class Assets extends Api {
  /**
   * Get assets
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of assets.
   */
  static async getAssets(queryParams = {}) {
    const response = await this.get("/assets").query(queryParams);
    return response.body;
  }

  /**
   * Get a asset
   * @async
   * @param {number} id Asset ID
   * @returns {Promise.<Object>} A single asset
   */
  static async getAsset(id) {
    const response = await this.get(`/assets/${id}`);
    return response.body.data;
  }

  /**
   * Create asset
   * @async
   * @param {Object} asset
   * @returns {Promise.<{id: number, location: string}>} Response
   */
  static async createAsset(asset) {
    const response = await this.post("/assets").send(asset);
    if (asset.dryRun) {
      return null;
    }
    return response.body.data;
  }

  /**
   * Update asset
   * @param {number} id
   * @param {Object} asset
   * @returns {Promise} Response
   */
  static async updateAsset(id: number, asset: TAsset) {
    const response = await this.put(`/assets/${id}`).send(asset);
    return response.body.data;
  }

  /**
   * Delete an asset
   * @param {number} id
   * @returns {Promise}
   */
  static async deleteAsset(id) {
    return await this.delete(`/assets/${id}`).send();
  }

  /**
   * Create an issue with an assigned asset
   * @async
   * @param {number} assetId
   * @param {Object} issue
   * @returns {Promise<*>}
   */
  static async createAssignmentIssue(assetId, issue = {}) {
    return await this.post(`/assets/${assetId}/assignment/issue`).send(issue);
  }

  /**
   * Get history events for an asset
   * @async
   * @param {number} id
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of history events.
   */
  static async getAssetHistory(id, queryParams = {}) {
    const response = await this.get(`/assets/${id}/history`).query(queryParams);
    return response.body;
  }

  /**
   * List assignments for an asset
   */
  static async listAssetAssignments(assetId: number): Promise<TAssetAssignment[]> {
    const response = await this.get(`/assets/${assetId}/assignments`);
    return response.body.data;
  }

  /**
   * Create an assignment for an asset
   */
  static async createAssetAssignment(assetId: number, userId: number) {
    return await this.post(`/assets/${assetId}/assignments`).send({
      userId,
    });
  }

  /**
   * Remove an asset assignment
   * @async
   * @param {number} assetId
   * @param {string} assignmentId Assignment ID
   * @param {Object} [requestBody]
   * @returns {Promise.<void>}
   */
  static async removeAssetAssignment(assetId, assignmentId, requestBody = {}) {
    return await this.delete(`/assets/${assetId}/assignments/${assignmentId}`).send(requestBody);
  }

  /**
   * Get an asset's calibration events
   * @param {number} assetId
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of calibration events.
   */
  static async getCalibrationEvents(assetId, queryParams = {}) {
    const response = await this.get(`/assets/${assetId}/calibrations`).query(queryParams);
    return response.body;
  }

  /**
   * Create a calibration event
   * @param {number} assetId
   * @param {Object} calibrationEvent
   * @returns {Promise<Object>}
   */
  static async createCalibrationEvent(assetId, calibrationEvent) {
    const response = await this.post(`/assets/${assetId}/calibrations`).send(calibrationEvent);
    return response.body.data;
  }
}

export default Assets;
