import { ReactNode } from "react";
import classNames from "classnames";
import styled from "styled-components";
import Files from "lib/api/files";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { ClickOverlay } from "components/shared/generic/ClickOverlay/ClickOverlay";
import style from "./avatar.module.scss";

const AvatarFrame = styled.div``;

export const AvatarImage = styled.div<{
  imageUrl?: string;
  size: AvatarProps["size"];
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0.5rem;
  ${({ imageUrl, size }) => ({
    backgroundImage: `url("${imageUrl}")`,
    borderRadius: size === "l" ? "1rem" : "0.5rem",
  })};
`;

export type AvatarProps = {
  image?: string;
  size?: "s" | "m" | "l";
  alt?: string;
  canEdit?: boolean;
  hoverIcon?: string | ReactNode;
  imageClassName?: string;
  className?: string;
  onChange?: (url: string) => void;
  onClick?: () => void;
};

export const Avatar = ({
  image,
  size = "l",
  alt,
  hoverIcon = "/images/icons/edit.svg",
  imageClassName,
  className,
  canEdit = false,
  onChange,
  onClick,
}: AvatarProps) => {
  const handleClick = () => {
    if (canEdit) {
      uploadImage();
    }
    if (typeof onClick === "function") {
      onClick();
    }
  };
  const uploadImage = () => {
    const input: HTMLInputElement = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = async () => {
      try {
        if (!input.files?.length) {
          return;
        }
        const file = input.files[0];
        const data = await Files.upload(file);
        if (onChange) {
          onChange(data.url);
        }
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
    input.click();
  };
  return (
    <AvatarFrame
      className={classNames(style.avatar, style[`size-${size}`], { [style.clickable]: canEdit || onClick }, className)}
      onClick={handleClick}
    >
      {image ? <AvatarImage imageUrl={image} size={size} className={imageClassName} /> : <AltText text={alt || ""} />}
      {canEdit || onClick ? <ClickOverlay size={size} icon={hoverIcon} /> : null}
    </AvatarFrame>
  );
};

type AltTextProps = {
  text: string;
};

const AltText = ({ text }: AltTextProps) => (
  <div className={style.alt}>
    <span>{text}</span>
  </div>
);

export default Avatar;
