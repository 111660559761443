import { useMemo } from "react";
import Resolve from "lib/Resolve";
import { getCustomer, getMe } from "lib/reducers/lookupData.selectors";
import { customerHasApp } from "lib/utility/customerHasApp.util";
import { useSelector } from "store";
import { FixLater } from "types";

/**
 * Utility function which searches through an array of roles and returns true if the role has the capability
 * @param {Object} me
 * @param {string} capabilityID
 * @returns {boolean}
 */
export const checkRoleHasCapability = (me: FixLater, capabilityID: FixLater) => {
  if (!me) {
    return false;
  }
  const role = Resolve.getRole(me.roleId);
  if (!role) {
    return false;
  }
  if (role.isAdmin) {
    return true;
  }
  if (!Array.isArray(role.capabilities)) {
    return false;
  }
  for (const capability of role.capabilities) {
    if (capability.capabilityId === capabilityID) {
      return true;
    }
  }
  return false;
};

export const useRoleHasCapability = (capabilityID: FixLater) => {
  const me = useSelector(getMe);
  return checkRoleHasCapability(me, capabilityID);
};

export const RestrictByApp = ({ app, alternate = null, children }: FixLater) => {
  const customer = useSelector(getCustomer);
  if (!customer) {
    return null;
  }
  return customerHasApp(customer.apps, app) ? children : alternate;
};

export const RestrictHasOneCapability = ({ capabilities, alternate = null, children }: FixLater) => {
  const me = useSelector(getMe);
  if (!me) {
    return null;
  }
  for (const capabilityID of capabilities) {
    if (checkRoleHasCapability(me, capabilityID)) {
      return children;
    }
  }
  return alternate;
};

export const RestrictHasAllCapability = ({
  capabilities,
  alternate = null,
  children,
}: FixLater): JSX.Element | null => {
  const me = useSelector(getMe);
  if (!me) {
    return null;
  }
  for (const capabilityID of capabilities) {
    if (!checkRoleHasCapability(me, capabilityID)) {
      return alternate;
    }
  }
  return children;
};

export const useIsAdmin = (): boolean => {
  const me = useSelector(getMe);
  if (!me) return false;
  const role = Resolve.getRole(me.roleId);
  return role?.isAdmin;
};

export const Restrict = ({ app, capability, alternate = null, children }: FixLater) => {
  const customer = useSelector(getCustomer);
  const me = useSelector(getMe);
  const hasCapability = useMemo(() => {
    if (!capability) {
      return true;
    }
    return checkRoleHasCapability(me, capability);
  }, [me]);
  const hasApp = useMemo(() => {
    if (!app) {
      return true;
    }
    return Boolean(customer && customerHasApp(customer.apps, app));
  }, [customer]);

  if (!customer) {
    return null;
  }

  return hasApp && hasCapability ? children : alternate;
};
