import React from "react";
import classNames from "classnames";
import Icon from "components/Icon";
import style from "./power.module.scss";

type PowerInputProps = {
  value: boolean | undefined;
  onChange: (value: boolean) => void;
  className?: string;
};

export const PowerInput = ({ value, onChange, className }: PowerInputProps) => {
  return (
    <div
      className={classNames(style.container, { [style.active]: !!value }, className)}
      onClick={() => onChange(!value)}
    >
      <div className={style.control}>
        <Icon src="/images/icons/power_1.svg" size="medium" color="font" className={style.icon} />
      </div>
    </div>
  );
};
