import { openModal } from "lib/actions/modal";
import { requestAccessPolicy } from "lib/actions/storages";
import { showPopupFlag } from "lib/actions/userInterface";
import API from "lib/api/accessControl";
import AccessControlAPI from "lib/api/accessControl";
import AccessScopes from "lib/constants/AccessScopes";
import Apps from "lib/constants/Apps";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectStorageByID, updateStorage } from "store/reducers";
import { FixLater } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import Loading from "components/Loading";
import { RestrictByApp } from "components/Restricted";
import { PowerInput } from "components/inputs/PowerInput";
import Pool from "components/pools/Pool";
import Swimlane from "components/pools/Swimlane";
import SwimlaneAccessCard from "components/pools/SwimlaneAccessCard";
import { SwimlaneHeader } from "components/pools/SwimlaneHeader";
import { VSpace } from "components/shared/layouts";
import { TextBody } from "components/shared/typography";

const filterByRuleScope = (scope: FixLater) => {
  return (grant: FixLater) => grant.rule.scope === scope;
};

type StorageAccessMatrixProps = {
  storageId: string;
  policy: FixLater;
};

export const StorageAccessMatrix = ({ storageId, policy }: StorageAccessMatrixProps) => {
  const dispatch = useDispatch();
  const storage = useSelector(selectStorageByID(storageId));
  const grants = policy ? policy.grants : [];
  const allowAnyoneGrant = grants.find((grant: FixLater) => grant.rule.scope === AccessScopes.ANYONE);

  const openViewRuleModal = (grant: FixLater) => {
    dispatch(
      openModal(Modals.ACCESS_RULE_VIEW, {
        storage,
        policy,
        grant,
      }),
    );
  };

  const toggleAnyoneRule = async () => {
    try {
      if (allowAnyoneGrant) {
        await API.removePolicyGrant(policy.id, allowAnyoneGrant.id);
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Disabled anonymous access",
          }),
        );
      } else {
        const newPolicy = {
          grants: [],
          ...policy,
        };
        const newGrant = {
          enabled: true,
          rule: {
            scope: AccessScopes.ANYONE,
          },
          allowKeycard: true,
          allowMobileApp: true,
        };
        newPolicy.grants = [...newPolicy.grants, newGrant];
        await AccessControlAPI.updatePolicy(newPolicy.id, newPolicy);
        dispatch(
          showPopupFlag({
            appearance: "warning",
            title: "Enabled anonymous access",
          }),
        );
      }
      dispatch(requestAccessPolicy(policy.id));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const toggleFaceAuth = async () => {
    try {
      if (!storage) return;
      const newStorage = {
        ...storage,
        faceIdEnabled: !storage.faceIdEnabled,
      };
      await dispatch(updateStorage(newStorage)).unwrap();
      dispatch(
        showPopupFlag({
          title: `${newStorage.faceIdEnabled ? "Enabled" : "Disabled"} face ID`,
          appearance: "success",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const toggleRequestVehicle = async () => {
    try {
      if (!storage) return;
      const newStorage = {
        ...storage,
        requestVehicle: !storage.requestVehicle,
      };
      await dispatch(updateStorage(newStorage)).unwrap();
      dispatch(
        showPopupFlag({
          title: `${newStorage.requestVehicle ? "Requesting" : "Not requesting"} vehicles`,
          appearance: newStorage.requestVehicle ? "success" : "info",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!storage) {
    return <Loading />;
  }

  return (
    <VSpace gap="gap-8">
      <DataGrid>
        <RestrictByApp app={Apps.FACIAL_AUTHENTICATION}>
          <DataItem
            label="Face ID"
            description="Authenticate users with facial recognition"
            value={<PowerInput value={storage.faceIdEnabled} onChange={toggleFaceAuth} />}
          />
        </RestrictByApp>
        <RestrictByApp app={Apps.VEHICLES}>
          <DataItem
            label="Request vehicle"
            description="Ask users to select a vehicle before access is granted"
            value={<PowerInput value={storage.requestVehicle} onChange={toggleRequestVehicle} />}
          />
        </RestrictByApp>
        <DataItem label="Allow anyone" value={<PowerInput value={!!allowAnyoneGrant} onChange={toggleAnyoneRule} />} />
      </DataGrid>
      {!allowAnyoneGrant && (
        <div>
          <Pool>
            <Swimlane>
              <SwimlaneHeader>Users</SwimlaneHeader>
              {grants.filter(filterByRuleScope(AccessScopes.USER)).map((grant: FixLater) => (
                <SwimlaneAccessCard key={grant.id} grant={grant} onClick={() => openViewRuleModal(grant)} />
              ))}
              {grants.filter(filterByRuleScope(AccessScopes.USER)).length === 0 && (
                <TextBody textColor="text-slate-400">No access</TextBody>
              )}
            </Swimlane>
            <Swimlane>
              <SwimlaneHeader>Roles</SwimlaneHeader>
              {grants.filter(filterByRuleScope(AccessScopes.ROLE)).map((grant: FixLater) => (
                <SwimlaneAccessCard key={grant.id} grant={grant} onClick={() => openViewRuleModal(grant)} />
              ))}
              {grants.filter(filterByRuleScope(AccessScopes.ROLE)).length === 0 && (
                <TextBody textColor="text-slate-400">No access</TextBody>
              )}
            </Swimlane>
          </Pool>
          <Pool>
            <Swimlane>
              <SwimlaneHeader>Keycards</SwimlaneHeader>
              {grants.filter(filterByRuleScope(AccessScopes.KEYCARD)).map((grant: FixLater) => (
                <SwimlaneAccessCard key={grant.id} grant={grant} onClick={() => openViewRuleModal(grant)} />
              ))}
              {grants.filter(filterByRuleScope(AccessScopes.KEYCARD)).length === 0 && (
                <TextBody textColor="text-slate-400">No access</TextBody>
              )}
            </Swimlane>
            <Swimlane>
              <SwimlaneHeader>Groups</SwimlaneHeader>
              {grants.filter(filterByRuleScope(AccessScopes.GROUP)).map((grant: FixLater) => (
                <SwimlaneAccessCard key={grant.id} grant={grant} onClick={() => openViewRuleModal(grant)} />
              ))}
              {grants.filter(filterByRuleScope(AccessScopes.GROUP)).length === 0 && (
                <TextBody textColor="text-slate-400">No access</TextBody>
              )}
            </Swimlane>
          </Pool>
        </div>
      )}
    </VSpace>
  );
};
