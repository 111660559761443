import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SortEnd, SortEvent } from "react-sortable-hoc";
import { useModal } from "effects";
import styled from "styled-components";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { selectAssetByID } from "store/reducers";
import { FixLater, TAsset, TStorageAsset, TStorageDrawer } from "types";
import { SecondaryButton } from "components/Buttons";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { RestrictByApp, RestrictHasAllCapability } from "components/Restricted";
import { TableLink } from "components/Table";
import { Subheader, Text } from "components/Typography";
import { SortableDataList, SortableDataListItem } from "components/lists/DataList";
import { AssetStatusBadge, ConfigureStorageAssetLayoutModal, Group, VSpace } from "components/shared";
import { StorageAssetRowDropdown } from "components/shared/features/assets/StorageAssetRowDropdown/StorageAssetRowDropdown";
import styles from "./inventory.module.scss";

const StyledTableLink = styled(TableLink)`
  flex-direction: column;
  align-items: flex-start;
`;

const AssetName = styled.span`
  display: block;
`;

const ProductNumber = styled.span`
  display: block;
  font-size: 0.875rem;
  color: #a6a6a6;
`;

const AssetUnavailableBadge = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: #f57c00;
  margin-left: 0.5rem;
`;

type DrawerAssetLocateButtonProps = {
  assetId: number;
  onClick?: () => void;
};

export const DrawerAssetLocateButton = ({ assetId, onClick }: DrawerAssetLocateButtonProps) => {
  const asset = useSelector(selectAssetByID(assetId));
  if (asset?.storageConfig?.svgPath) {
    return null;
  }
  return (
    <SecondaryButton icon="/images/icons/tracking_1.svg" onClick={onClick}>
      Locate
    </SecondaryButton>
  );
};

type AssetNameLinkProps = {
  assetId: number;
};

const AssetNameLink = ({ assetId }: AssetNameLinkProps) => {
  const asset = useSelector(selectAssetByID(assetId));
  if (!asset) {
    return null;
  }
  const { catalogProduct, available } = asset;
  if (!catalogProduct) {
    return null;
  }

  return (
    <StyledTableLink to={`/apps/tracking/assets/${assetId}`} target="_blank">
      <AssetName>
        {catalogProduct.name}
        {!available && (
          <Popover
            content={
              <div>
                <Text>Tool unavailable</Text>
              </div>
            }
          >
            <AssetUnavailableBadge />
          </Popover>
        )}
      </AssetName>
      <ProductNumber>
        {catalogProduct.mpn}
        {catalogProduct.mpn && asset.serialNumber ? " / " : ""}
        {asset.serialNumber}
      </ProductNumber>
    </StyledTableLink>
  );
};

type StorageDrawerInventoryProps = {
  drawer: FixLater;
  loading: boolean;
  filtering?: boolean;
  assets: TAsset[];
  onSortEnd: (sort: SortEnd, event: SortEvent) => void;
  onAssetAssign: (drawer: TStorageDrawer) => void;
  onAssetUnAssign: (assetId: number) => void;
};

export const StorageDrawerInventory = ({
  drawer,
  loading,
  filtering = false,
  assets,
  onSortEnd,
  onAssetAssign,
  onAssetUnAssign,
}: StorageDrawerInventoryProps) => {
  const { openModal } = useModal();
  const [empty, setEmpty] = useState(false);
  const [drawerAssets, setDrawerAssets] = useState<TAsset[]>([]);

  useEffect(() => {
    setDrawerAssets(assets);
    const count = assets.filter(({ storageConfig }) => storageConfig?.drawerNumber === drawer.value).length;
    setEmpty(count === 0);
  }, [assets, drawer]);

  if (filtering && !drawerAssets.length) {
    return null;
  }

  const locate = (storageConfig?: TStorageAsset) => {
    if (!storageConfig) return;
    openModal(
      <ConfigureStorageAssetLayoutModal
        storageId={storageConfig.storageId}
        assetId={storageConfig.assetId}
        drawer={drawer}
        drawerAssets={drawerAssets}
      />,
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }
    if (empty) {
      return (
        <Group alignVertical spaceBetween>
          <Text muted>{filtering ? "This drawer has no results matching filters" : "This drawer is empty"}</Text>
        </Group>
      );
    }
    return (
      <SortableDataList onSortEnd={onSortEnd} useDragHandle>
        {drawerAssets.map((asset: TAsset, i) => {
          const { id, storageConfig } = asset;
          if (!storageConfig) return null;
          if (storageConfig.drawerNumber !== drawer.value) return null;
          return (
            <SortableDataListItem key={asset.id} index={i}>
              <div className={styles.StorageDrawerInventoryItem}>
                <AssetNameLink assetId={asset.id} />
                <div className={styles.StorageDrawerInventoryItemToolbox}>
                  <RestrictByApp app={Apps.MODBUS}>
                    <Text muted>Modbus address: {storageConfig.order}</Text>
                  </RestrictByApp>

                  <AssetStatusBadge assetId={asset.guid} />

                  {drawer.fullSvg && drawer.svgPath && (
                    <DrawerAssetLocateButton assetId={id} onClick={() => locate(storageConfig)} />
                  )}
                  <StorageAssetRowDropdown
                    assetId={asset.id}
                    onConfigure={() => locate(storageConfig)}
                    onDelete={() => onAssetUnAssign(asset.id)}
                  />
                </div>
              </div>
            </SortableDataListItem>
          );
        })}
      </SortableDataList>
    );
  };

  return (
    <div className={styles.StorageDrawerInventory}>
      <VSpace gap="gap-2">
        <Subheader className={styles.StorageDrawerHeader}>
          {drawer.name || `Drawer ${drawer.value}`}
          <RestrictHasAllCapability capabilities={[Capabilities.STORAGE_UPDATE, Capabilities.ASSET_UPDATE]}>
            <SecondaryButton onClick={() => onAssetAssign(drawer)} icon="/images/icons/plus_1.svg">
              Assign tool
            </SecondaryButton>
          </RestrictHasAllCapability>
        </Subheader>
        {renderContent()}
      </VSpace>
    </div>
  );
};
