import { TUser } from "types";
import Avatar, { AvatarProps } from "components/Avatar";

export type UserAvatarProps = Pick<AvatarProps, "size"> & {
  user: Pick<TUser, "profileImage" | "email">;
};

export const UserAvatar = ({ user, size = "s" }: UserAvatarProps) => {
  return <Avatar image={user.profileImage} alt={user.email.toUpperCase().slice(0, 2)} size={size} />;
};
