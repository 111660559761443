import { useMemo } from "react";
import { CursorClickIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import arrayMove from "array-move";
import { useModal } from "effects";
import { v4 as uuidv4 } from "uuid";
import StorageModels from "lib/constants/StorageModels";
import { FixLater, TStorage, TStorageDrawer } from "types";
import Popover from "components/Popover";
import { Text } from "components/Typography";
import { SortableDataList, SortableDataListItem } from "components/lists/DataList";
import { SecondaryButton } from "components/shared/buttons";
import { ConfigureDrawerLayoutModal } from "components/shared/features/storages/modals/ConfigureDrawerLayoutModal/ConfigureDrawerLayoutModal";
import { HSpace } from "components/shared/layouts";
import { TextBody } from "components/shared/typography";
import styles from "./DrawerNames.module.scss";

type DrawerNamesProps = {
  storage: TStorage;
  onChange: (rearranged: TStorageDrawer[]) => void;
};

export const DrawerNames = ({ storage, onChange }: DrawerNamesProps) => {
  const { openModal } = useModal();

  const drawers = useMemo((): TStorageDrawer[] => {
    const model = StorageModels.resolveItem(storage.model);
    let drawers: TStorageDrawer[] = [];
    drawers.length = model?.drawerCount || 0;
    // Populate with defaults
    for (let i = 0; i < drawers.length; i++) {
      drawers[i] = {
        id: uuidv4(),
        storageId: storage.id,
        name: "",
        value: i + 1,
        order: i,
        dateCreated: new Date().toISOString(),
      };
    }

    // Populate with existing drawers
    for (const drawer of storage.drawers) {
      try {
        drawers[drawer.value - 1] = { ...drawer };
      } catch (err) {
        console.error(err);
      }
    }

    return drawers.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [storage.drawers]);

  const handleSortEnd = ({ oldIndex, newIndex }: FixLater) => {
    let rearranged = arrayMove(drawers, oldIndex, newIndex);
    for (let i = 0; i < drawers.length; i++) {
      rearranged[i].order = i;
    }
    onChange(rearranged);
  };

  const changeLayout = (drawer: TStorageDrawer) => {
    openModal(
      <ConfigureDrawerLayoutModal
        drawer={drawer}
        onSuccess={(value) => {
          onChange(
            drawers.map((d) => {
              if (d.id === drawer.id) {
                return { ...d, ...value };
              }
              return d;
            }),
          );
        }}
      />,
    );
  };

  return (
    <SortableDataList onSortEnd={handleSortEnd} useDragHandle>
      {drawers.map((drawer, i) => {
        const handleConfirm = (value: string) => {
          try {
            drawers[i].name = value;
            onChange && onChange(drawers);
          } catch (err) {
            console.error(err);
          }
        };
        return (
          <SortableDataListItem key={drawer.value} index={drawer.order} id={drawer.value}>
            <HSpace>
              <div className="flex-1 flex-grow">
                <Text
                  canEdit
                  value={drawer.name}
                  placeholder={`Drawer ${drawer.value}`}
                  onConfirm={handleConfirm}
                  className={styles.DrawerNamesInput}
                />
              </div>
              <HSpace className="flex flex-grow-0 items-center justify-center">
                {drawer && (!drawer.irSensorBoardId || !drawer.irSensorThreshold) && (
                  <Popover
                    preferPlace="above"
                    content={<TextBody textColor="text-white">Sensor settings not configured</TextBody>}
                    className="flex items-center justify-center"
                  >
                    <ExclamationCircleIcon className="text-gray-400 h-5 w-5 flex-shrink-0" />
                  </Popover>
                )}
                <SecondaryButton leadingIcon={CursorClickIcon} onClick={() => changeLayout(drawer)}>
                  Configure
                </SecondaryButton>
              </HSpace>
            </HSpace>
          </SortableDataListItem>
        );
      })}
    </SortableDataList>
  );
};
