//@ts-nocheck
import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { KanbanColumn } from "components/shared";
import styles from "./KanbanBoard.module.scss";

export const KanbanBoard = ({ columns, onMoveColumn }) => {
  const [droppableSourceID, setDroppableSourceID] = useState(null);

  const onDragStart = (start) => {
    const { source } = start;
    setDroppableSourceID(source.droppableId);
  };

  const onDragEnd = (result) => {
    setDroppableSourceID(null);
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    const start = columns.find((column) => column.columnID === source.droppableId);
    const item = start.items.find((item) => item.id === draggableId);
    const finish = columns.find((column) => column.columnID === destination.droppableId);
    onMoveColumn(item, start, finish);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <div className={styles.KanbanBoard}>
        {columns.map((column) => {
          const { columnID } = column;
          return <KanbanColumn key={column.id} isDropDisabled={droppableSourceID === columnID} {...column} />;
        })}
      </div>
    </DragDropContext>
  );
};
