//@ts-nocheck
import Store from "lib/Store";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import ReportVisibility from "lib/constants/ReportVisibility";
import StorageModels from "lib/constants/StorageModels";
import { formatFullName } from "lib/utility/format";
import reduxStore from "store";
import { CalibrationTrackingMethodOption, TUser } from "types";

class Resolve {
  /**
   *
   * @returns {{name: string, navbarLogo: string}}
   */
  static brand(): { id: string; name: string; navbarLogo: string } {
    if (!process.env.REACT_APP_BRAND) {
      throw new Error("Brand information is not available");
    }
    return process.env.REACT_APP_BRAND as never;
  }

  /**
   * Get all roles
   * @return {[]}
   */
  static getRoles() {
    const { lookupData } = reduxStore.getState();
    return lookupData.roles.slice(0);
  }

  /**
   * Get lookup projects
   * @return {Array.<Object>}
   */
  static getProjects() {
    const { lookupData } = reduxStore.getState();
    return lookupData.projects.slice(0);
  }

  /**
   * Get lookup users
   * @return {Array.<Object>}
   */
  static getUsers() {
    const { lookupData } = reduxStore.getState();
    return lookupData.users.slice(0);
  }

  /**
   * Get a role by ID
   * @param {number} roleId
   * @return {Object}
   */
  static getRole(roleId) {
    const roles = Resolve.getRoles();
    return roles.find(({ id }) => id === roleId);
  }

  /**
   * Get a list of report visibilities
   * @return {Array.<{value: number, label: string}>}
   */
  static getReportVisibilities() {
    return ReportVisibility.items;
  }

  /**
   * Get a list of storage models. This is the toolbox serial number.
   * @return {Array.<{value: number, label: string}>}
   */
  static getStorageModels() {
    return StorageModels.items.map(({ label, value }) => ({
      label,
      value,
    }));
    // return [{
    //     label: "TCW207N - 37\" Roller Cabinet",
    //     value: "TCW207N"
    // }, {
    //     label: "TCW208NBK - 37\" Black Tool Cabinet",
    //     value: "TCW208NBK"
    // }]
  }

  /**
   * Get a list of template types
   * @return {Array.<{id: number, label: string}>}
   */
  static getTemplateTypes() {
    return [
      {
        label: "Raw",
        id: 1,
      },
      {
        label: "HTML",
        id: 2,
      },
      {
        label: "XML",
        id: 3,
      },
      {
        label: "CSV",
        id: 4,
      },
    ];
  }

  /**
   * Get a list of catalogue product measurement types
   * @return {Array.<{id: number, label: string}>}
   */
  static getProductMeasurementTypes() {
    return [
      {
        id: 1,
        label: "N/A",
      },
      {
        id: 2,
        label: "Metric",
      },
      {
        id: 3,
        label: "Imperial",
      },
    ];
  }

  /**
   * Get a list of catalogue product types
   * @return {Array.<{id: number, label: string}>}
   */
  static getProductTypes() {
    return [];
  }

  /**
   * Get a list of project permissions
   * @return {Array.<{label: string, value: number}>} Project permissions
   */
  static getProjectPermissions() {
    return [
      {
        label: "Anyone",
        value: 1,
      },
      {
        label: "Project members",
        value: 2,
      },
      {
        label: "Managers",
        value: 3,
      },
    ];
  }

  /**
   * Get a list of antenna types
   * @return {Array.<{label: string, value: number}>} Antenna types
   */
  static getAntennaTypes() {
    return [
      {
        label: "Storage",
        value: 1,
      },
      {
        label: "Doorway",
        value: 2,
      },
      {
        label: "Access gateway",
        value: 3,
      },
    ];
  }

  /**
   * Get a list of calibration tracking methods
   * @return {Array.<{label: string, id: number}>} Methods
   */
  static getCalibrationTrackingMethods(): CalibrationTrackingMethodOption[] {
    return [
      {
        label: "Calendar",
        description: "Track by calendar date.",
        value: CalibrationTrackingMethods.TIME,
      },
      {
        label: "Actuation",
        description: "Track by how many actuations a tool undergoes.",
        value: CalibrationTrackingMethods.ACTUATION,
      },
      {
        label: "Calendar + Actuation",
        description: "Track by calendar date or actuations, which ever comes first.",
        value: CalibrationTrackingMethods.TIME_AND_ACTUATION,
      },
    ];
  }

  /**
   * Get a project by ID
   * @param {number} projectId
   * @return {Object|null}
   */
  static getProject(projectId) {
    const projects = Resolve.getProjects();
    return projects.find((project) => project.id === projectId);
  }

  /**
   * Resolve the name of a role by ID
   * @param {number} roleId
   * @return {string}
   */
  static resolveRoleName(roleId) {
    const roles = Resolve.getRoles();
    const role = roles.find(({ id }) => id === roleId);
    if (role != null) {
      return role.name;
    }
    return "";
  }

  /**
   * Get all tag types
   * @return {[]}
   */
  static getTagTypes() {
    return [
      { id: 0, name: "Unknown" },
      { id: 1, name: "Barcode" },
      { id: 2, name: "RFID tag" },
    ];
  }

  /**
   * Get all asset statuses
   * @return {[]}
   */
  static getAssetStatuses() {
    return [
      { id: 0, name: "Unknown" },
      { id: 1, name: "Un-assigned" },
      { id: 2, name: "Assigned" },
    ];
  }

  /**
   * Get all locations
   * @return {[]}
   */
  static getLocations() {
    const { locations } = reduxStore.getState();
    return Object.values(locations.data).slice(0);
  }

  /**
   * Resolve location by name
   */
  static resolveLocationByName(name): TLocation | undefined {
    const locations = Resolve.getLocations();
    return locations.find((location) => location.name.toLowerCase() === name.toLowerCase());
  }

  /**
   * Resolve the name of a tag type by ID
   * @param {number} typeId
   * @return {string}
   */
  static resolveTagTypeName(typeId) {
    const type = this.getTagTypes().find(({ id }) => id === typeId);
    if (type != null) {
      return type.name;
    }
    return "";
  }

  /**
   * Resolve the name of an asset status by ID
   * @param {number} status
   * @return {string}
   */
  static resolveAssetStatusName(status) {
    const type = this.getAssetStatuses().find(({ id }) => id === status);
    if (type != null) {
      return type.name;
    }
    return "";
  }

  /**
   * Get a location by ID
   * @param {number} locationId
   * @return {Object}
   */
  static resolveLocation(locationId) {
    const { locations } = reduxStore.getState();
    return Object.values(locations.data).find(({ id }) => id === locationId);
  }

  /**
   * Resolve the name of a location by ID
   * @param {number} locationId
   * @return {string}
   */
  static resolveLocationName(locationId) {
    const { locations } = reduxStore.getState();
    const location = Object.values(locations.data).find(({ id }) => id === locationId);
    if (location != null) {
      return location.name;
    }
    return "";
  }

  /**
   * Resolve a asset by ID
   * @param {number} assetId
   * @return {string}
   */
  static resolveAsset(assetId) {
    const asset = Store.collection("assets").findOne({ id: assetId });
    return asset ? asset : null;
  }

  /**
   * Resolve the name of a asset by ID
   * @param {number} assetId
   * @return {string}
   */
  static resolveAssetName(assetId) {
    const asset = Resolve.resolveAsset(assetId);
    return asset ? asset.name : "";
  }

  /**
   * Resolve the name of a project
   * @param {number} projectId
   * @returns {string} Project name
   */
  static resolveProjectName(projectId) {
    const project = Resolve.getProject(projectId);
    return project ? project.name : "";
  }

  /**
   * Resolve a antenna type by ID
   * @param {number} antennaType
   * @returns {{label: string, value: number} | null}
   */
  static resolveAntennaType(antennaType) {
    const types = Resolve.getAntennaTypes();
    const type = types.find((type) => type.value === antennaType);
    return type ? type : null;
  }

  /**
   * Resolve the name of a antenna type ID
   * @param {number} antennaType
   * @returns {string} Antenna type name
   */
  static resolveAntennaTypeName(antennaType) {
    const type = Resolve.resolveAntennaType(antennaType);
    return type ? type.label : "";
  }

  /**
   * Resolve product type by ID
   * @param {number} typeId
   * @returns {{label: string, value: number} | null}
   */
  static resolveProductType(typeId) {
    const types = Resolve.getProductTypes();
    const type = types.find((type) => type.id === typeId);
    return type ? type : null;
  }

  /**
   * Resolve a product type name by ID
   * @param {number} typeId
   * @returns {string}
   */
  static resolveProductTypeName(typeId) {
    const type = Resolve.resolveProductType(typeId);
    return type ? type.label : "";
  }

  /**
   * Resolve report visibility by ID
   * @param {number} value
   * @returns {{label: string, value: number} | null}
   */
  static resolveReportVisibility(value) {
    const visibilities = Resolve.getReportVisibilities();
    const visibility = visibilities.find((visibility) => visibility.value === value);
    return visibility ? visibility : null;
  }

  /**
   * Resolve a report visibility name by ID
   * @param {number} id
   * @returns {string}
   */
  static resolveReportVisibilityName(id) {
    const visibility = Resolve.resolveReportVisibility(id);
    return visibility ? visibility.label : "";
  }

  /**
   * Resolve template type by ID
   * @param {number} typeId
   * @returns {{label: string, value: number} | null}
   */
  static resolveTemplateType(typeId) {
    const types = Resolve.getTemplateTypes();
    const type = types.find((type) => type.id === typeId);
    return type ? type : null;
  }

  /**
   * Resolve a template type name by ID
   * @param {number} typeId
   * @returns {string}
   */
  static resolveTemplateTypeName(typeId) {
    const type = Resolve.resolveTemplateType(typeId);
    return type ? type.label : "";
  }

  /**
   * Resolve product measurement type by ID
   * @param {number} typeId
   * @returns {{label: string, id: number} | null}
   */
  static resolveProductMeasurementType(typeId) {
    const types = Resolve.getProductMeasurementTypes();
    const type = types.find((type) => type.id === typeId);
    return type ? type : null;
  }

  /**
   * Resolve a product measurement type name by ID
   * @param {number} typeId
   * @returns {string}
   */
  static resolveProductMeasurementTypeName(typeId) {
    const type = Resolve.resolveProductMeasurementType(typeId);
    return type ? type.label : "";
  }

  /**
   * Get customer information
   * @return {Object|null}
   */
  static resolveCustomer() {
    const { lookupData } = reduxStore.getState();
    return lookupData.customer ? lookupData.customer : null;
  }

  /**
   * Get customer name
   * @return {string}
   */
  static resolveCustomerName() {
    const customer = Resolve.resolveCustomer();
    return customer ? customer.name : "";
  }

  /**
   * Get customer apps
   * @return {Array.<string>}
   */
  static resolveCustomerApps() {
    const customer = Resolve.resolveCustomer();
    return customer ? customer.apps : [];
  }

  /**
   * Get project permission by value
   * @return {Object|null}
   */
  static resolveProjectPermission(value) {
    const permissions = Resolve.getProjectPermissions();
    const permission = permissions.find((permission) => permission.value === value);
    return permission ? permission : null;
  }

  /**
   * Get project permission name by value
   * @return {Object|null}
   */
  static resolveProjectPermissionName(value) {
    const permission = Resolve.resolveProjectPermission(value);
    return permission ? permission.label : "";
  }

  /**
   * Resolve a storage model by ID
   * @param {number} modelValue
   * @returns {string}
   */
  static resolveStorageModel(modelValue) {
    const model = Resolve.getStorageModels().find((model) => model.value === modelValue);
    return model ? model : null;
  }

  /**
   * Resolve a storage model name by ID
   * @param {number} modelValue
   * @returns {string}
   */
  static resolveStorageModelName(modelValue) {
    const model = Resolve.resolveStorageModel(modelValue);
    return model ? model.label : "";
  }

  /**
   * Resolve a calibration tracking method by ID
   * @param {number} methodId
   * @returns {Object|null}
   */
  static resolveCalibrationTrackingMethod(methodId) {
    const method = Resolve.getCalibrationTrackingMethods().find((method) => method.value === methodId);
    return method ? method : null;
  }

  /**
   * Resolve a calibration tracking method name by ID
   * @param {number} methodId
   * @returns {string}
   */
  static resolveCalibrationTrackingMethodName(methodId) {
    const method = Resolve.resolveCalibrationTrackingMethod(methodId);
    return method ? method.label : "";
  }

  /**
   * Resolve a lookup user by user ID
   */
  static resolveUser(userId: number): TUser | null {
    const user = Resolve.getUsers().find(({ id }) => id === userId);
    return user ? user : null;
  }

  /**
   * Resolve the user full name. Empty string is returned if the user does not exist or they
   * dont have a first and last name.
   * @param {number} userId
   * @returns {string}
   */
  static resolveUserFullName(userId) {
    const user = Resolve.resolveUser(userId);
    if (!user) {
      return "";
    }
    return formatFullName({
      forename: user.forename,
      surname: user.surname,
      email: user.email,
    });
  }

  /**
   * Resolve the current authenticated user. Null is returned if the user is not authenticated.
   * @returns {Object|null}
   */
  static resolveMe() {
    const { lookupData } = reduxStore.getState();
    return lookupData.me ? lookupData.me : null;
  }

  /**
   * Resolve the customer branding. Null is returned if the user is not authenticated.
   * @returns {Object|null}
   */
  static resolveBranding() {
    const { lookupData } = reduxStore.getState();
    return lookupData.branding ? lookupData.branding : null;
  }
}

export default Resolve;
