import { useState } from "react";
import { useModal } from "effects";
import moment from "moment";
import styled from "styled-components";
import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import Api from "lib/api/assets";
import Apps from "lib/constants/Apps";
import CheckoutTypes from "lib/constants/CheckoutTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssetById, selectAssetByID } from "store/reducers";
import { TAssetAssignment } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { Restrict } from "components/Restricted";
import { Text, Anchor } from "components/Typography";
import { UserAvatar, VehicleName, VSpace } from "components/shared";
import { TrashButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";

const RemoveButton = styled(TrashButton)`
  font-size: 1.5rem;
`;

type AssetAssignmentDataItemProps = {
  assignment: TAssetAssignment;
};

export const AssetAssignmentDataItem = ({ assignment }: AssetAssignmentDataItemProps) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assignment.assetId));
  const user = assignment.userId ? Resolve.resolveUser(assignment.userId) : null;
  const [removing, setRemoving] = useState<boolean>(false);

  const removeAssignment = () => {
    openModal(
      <ConfirmModal
        actionText="Remove assignment"
        description="Are you sure you want to remove this tool assignment?"
        onConfirm={async () => {
          try {
            setRemoving(true);
            await Api.removeAssetAssignment(assignment.assetId, assignment.id);
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Check-in successful",
              }),
            );
            dispatch(fetchAssetById(assignment.assetId));
          } catch (err) {
            setRemoving(false);
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  return (
    <VSpace className="p-4">
      <VSpace direction="flex-row" className="justify-between">
        <Text className="text-lg font-semibold">Assignment details</Text>
        {removing ? <Loading /> : <RemoveButton onClick={removeAssignment} />}
      </VSpace>
      <DataGrid>
        <DataItem
          label="Team member:"
          value={
            user ? (
              <VSpace direction="flex-row" className="items-center">
                <UserAvatar user={user} />
                <Anchor to={`/configuration/users/${assignment.userId}`}>{`${user.forename} ${user.surname}`}</Anchor>
              </VSpace>
            ) : (
              <div className="flex">
                <Popover content={<Text>Automify could not determine the user</Text>}>
                  <Text className="text-muted">Not assigned</Text>
                </Popover>
              </div>
            )
          }
        />
        <DataItem label="Checkout time:" value={<Text>{moment(assignment.dateAssigned).format("lll")}</Text>} />
        <DataItem
          label="Checkout method:"
          value={
            <>
              <Text>{CheckoutTypes.resolveItemLabel(assignment.typeId)}</Text>
            </>
          }
        />
        {asset?.fractional && (
          <DataItem
            label="Amount:"
            value={
              <Text>
                {assignment.fractions}
                {asset.fractions ? `/${asset.fractions}` : ""}
              </Text>
            }
          />
        )}
        <Restrict app={Apps.VEHICLES}>
          <DataItem
            label="Vehicle:"
            value={
              assignment.vehicleId ? (
                <Anchor to="/apps/vehicles">
                  <VehicleName vehicleId={assignment.vehicleId} />
                </Anchor>
              ) : (
                <Text className="text-gray-400">N/A</Text>
              )
            }
          />
        </Restrict>
      </DataGrid>
    </VSpace>
  );
};
