import { Menu } from "@headlessui/react";
import { TrashIcon, DotsVerticalIcon, PlayIcon, PauseIcon, HandIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteVehicle, selectVehicleByID, updateVehicle } from "store/reducers";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type VehicleTableRowDropdownProps = {
  vehicleId: string;
  onDelete?: () => void;
};

export const VehiclesTableRowDropdown = ({ vehicleId, onDelete }: VehicleTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const vehicle = useSelector(selectVehicleByID(vehicleId));

  if (!vehicle) {
    return null;
  }

  const removeVehicle = () => {
    openModal(
      <ConfirmModal
        actionText="Remove vehicle"
        description="Are you sure you want to delete this vehicle? Doing this is permanent."
        onConfirm={async () => {
          try {
            await dispatch(deleteVehicle(vehicleId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Vehicle was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const toggleAvailable = async () => {
    try {
      const { available, locationId } = vehicle;
      await dispatch(
        updateVehicle({
          ...vehicle,
          available: !available,
          // Here we remove the location from the vehicle when it becomes unavailable
          locationId: !available ? undefined : locationId,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: available ? "info" : "success",
          title: available ? "Vehicle unavailable" : "Vehicle available",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const toggleLiveStream = async () => {
    try {
      const { liveStream } = vehicle;
      await dispatch(
        updateVehicle({
          ...vehicle,
          liveStream: !liveStream,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: liveStream ? "info" : "success",
          title: liveStream ? "Live stream disabled" : "Live stream enabled",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      {
        label: vehicle.available ? "Make unavailable" : "Make available",
        icon: vehicle.available ? PauseIcon : PlayIcon,
        onClick: toggleAvailable,
      },
      {
        label: vehicle.liveStream ? "Disable live stream" : "Enable live stream",
        icon: vehicle.liveStream ? HandIcon : PlayIcon,
        onClick: toggleLiveStream,
      },
    ],
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: removeVehicle,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
