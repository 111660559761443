import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showPopupFlag } from "lib/actions/userInterface";
import AlertRules from "lib/constants/AlertRules";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteAlert, fetchAlertById, selectAlertByID, updateAlert } from "store/reducers";
import { TAlert } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import { Header, Paragraph } from "components/Typography";
import { AlertRecipientForm, AlertRemoveButton, AlertRulesForm, PageLoading, VSpace } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ToolTrackingAlertPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alertID } = useParams();
  const alert = useSelector(selectAlertByID(alertID || ""));

  useEffect(() => {
    if (!alertID) return;
    dispatch(fetchAlertById(alertID));
  }, [alertID]);

  const saveChanges = (name: keyof TAlert) => {
    return async (value: string) => {
      try {
        if (!alert) return;
        await dispatch(
          updateAlert({
            ...alert,
            [name]: value,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Changes saved",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleRemoveAlert = async () => {
    try {
      if (!alertID) return;
      await dispatch(deleteAlert(alertID)).unwrap();
      navigate("/apps/tracking/alerts", {
        replace: true,
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!alert) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <Header canEdit placeholder="Enter alert name" value={alert.name} onConfirm={saveChanges("name")} />
      <Paragraph
        canEdit
        size="large"
        placeholder="Enter alert description"
        value={alert.description}
        onConfirm={saveChanges("description")}
      />
      <VSpace>
        <AlertRulesForm
          alertId={alert.id}
          ruleKeys={[
            AlertRules.ASSET_ISSUE_CREATED,
            AlertRules.CALIBRATION_VIOLATION,
            AlertRules.CALIBRATION_NEAR_DEADLINE,
          ]}
        />
        <AlertRecipientForm alertId={alert.id} />
        <DataGrid>
          <DataItem label="Remove alert" value={<AlertRemoveButton onClick={handleRemoveAlert} />} />
        </DataGrid>
      </VSpace>
    </PageStage>
  );
};
