import { useDispatch, useSelector } from "react-redux";
import { requestProjectSuccess } from "lib/actions/projects";
import Projects from "lib/api/projects";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getProjectByID } from "lib/reducers/projects.selectors";
import { FixLater } from "types";
import { DateInput } from "components/Input";
import UsersInput from "components/inputs/UsersInput";
import { ProjectStatusSelector, ProjectPrioritySelector } from "components/shared";

type ProjectSheetSidebarProps = {
  projectId: number;
};

export const ProjectSheetSidebar = ({ projectId }: ProjectSheetSidebarProps) => {
  const dispatch = useDispatch();
  const project = useSelector(getProjectByID(projectId));

  const handleChange = async (name: string, value: FixLater) => {
    try {
      if (project[name] === value) {
        // Do nothing if the status does not change
        return;
      }
      const requestBody = {
        ...project,
        [name]: value,
      };
      await Projects.updateProject(project.id, requestBody);
      dispatch(requestProjectSuccess(requestBody));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!project) {
    return null;
  }

  return (
    <dl className="space-y-8 sm:space-y-6">
      <div>
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Status</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
          <ProjectStatusSelector
            name="status"
            value={project.status}
            onChange={(name: string, { value }: { value: FixLater }) => handleChange(name, value)}
          />
        </dd>
      </div>
      <div>
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Assignee</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
          <UsersInput
            name="assignee"
            value={project.assignee}
            onChange={(name: string, value: FixLater) => handleChange(name, value ? value.id : null)}
          />
        </dd>
      </div>
      <div>
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Priority</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
          <ProjectPrioritySelector
            name="priority"
            value={project.priority}
            onChange={(name: string, { value }: { value: FixLater }) => handleChange(name, value)}
          />
        </dd>
      </div>
      <div>
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Due date</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
          <DateInput
            input={{
              name: "dateDue",
              placeholder: "Enter a due date",
            }}
            value={project.dateDue}
            onChange={handleChange}
          />
        </dd>
      </div>
    </dl>
  );
};
