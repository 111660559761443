import { useModal } from "effects";
import styled from "styled-components";
import { Paragraph } from "components/Typography";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { HSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";

const StyledModal = styled(Modal)`
  min-width: unset;
  width: 100%;
  max-width: 30rem;
  text-align: center;
`;

type ConfirmModalProps = {
  title?: string;
  description?: string;
  actionText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ConfirmModal = ({
  title = "Are you sure?",
  description = "Please confirm that you would like to do this action.",
  actionText = "Confirm",
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <StyledModal>
      <ModalHeader title={title} />
      {description && (
        <ModalContent>
          <Paragraph>{description}</Paragraph>
        </ModalContent>
      )}
      <HSpace>
        <ModalSecondaryActionButton onClick={handleCancel}>Cancel</ModalSecondaryActionButton>
        <ModalPrimaryActionButton type="submit" onClick={handleConfirm}>
          {actionText}
        </ModalPrimaryActionButton>
      </HSpace>
    </StyledModal>
  );
};
