import { useSelector } from "react-redux";
import { selectAssetByGUID } from "store/reducers";
import { UUID } from "types";
import StatusPill from "components/StatusPill";

export type AssetStatusBadgeProps = {
  assetId: UUID;
};

export const AssetStatusBadge = ({ assetId }: AssetStatusBadgeProps) => {
  const asset = useSelector(selectAssetByGUID(assetId));
  if (!asset) return null;
  if (!asset.available) return null;
  return asset.assignments?.length ? (
    <StatusPill text="In Use" color="warning" />
  ) : (
    <StatusPill text="Available" color="success" />
  );
};
