import React, { useState } from "react";
import { matchPath } from "react-router-dom";
import dayjs from "dayjs";
import { useModal } from "effects";
import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  selectAssets,
  selectToolStoreOrderByID,
  selectToolStoreOrderItemsByOrderID,
  updateToolStoreOrder,
  updateToolStoreOrderItem,
} from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import { PrimaryButton, SecondaryButton } from "components/shared/buttons";
import { QRCodeScannerModal } from "components/shared/features/qrscanner/QRCodeScannerModal/QRCodeScannerModal";
import { ToolStoreOrderSlideOverItem } from "components/shared/features/toolStoreOrders/ToolStoreOrderSlideOverItem/ToolStoreOrderSlideOverItem";

type ToolStoreOrderRequesterProps = {
  toolStoreOrderId: UUID;
};

export const ToolStoreOrderItems = ({ toolStoreOrderId }: ToolStoreOrderRequesterProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const entity = useSelector(selectToolStoreOrderByID(toolStoreOrderId));
  const assets = useSelector(selectAssets);
  const items = useSelector(selectToolStoreOrderItemsByOrderID(toolStoreOrderId));

  const toggleAllReadyForDelivery = async () => {
    try {
      if (!entity) {
        return;
      }
      setLoading(true);
      await Promise.all(
        items
          .filter((item) => !item.dateReadyForDelivery)
          .filter((item) => {
            const asset = assets[item.assetId];
            if (!asset) return false;
            return asset.fractional ? true : asset.assignments.length === 0;
          })
          .map(async (item) => {
            return dispatch(
              updateToolStoreOrderItem({
                ...item,
                toolStoreId: entity.toolStoreId,
                dateReadyForDelivery: dayjs().utc().toISOString(),
              }),
            ).unwrap();
          }),
      );
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Tools marked ready for delivery",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const confirmOrderInTransit = async () => {
    try {
      if (!entity) {
        return;
      }
      setLoading(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "in_transit",
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Order in transit",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const fulfilOrderReturned = async () => {
    try {
      if (!entity) {
        return;
      }
      setLoading(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "fulfilled",
        }),
      ).unwrap();

      // TODO: Fetch the latest assets for this order.

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Order fulfilled",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const scanQR = () => {
    openModal(
      <QRCodeScannerModal
        onSuccess={async (data) => {
          const normalised = data.replace(window.location.origin, "");
          const match = matchPath(
            {
              path: "/apps/tracking/assets/:assetId",
            },
            normalised,
          );

          if (!entity) {
            return;
          }

          if (!match || !match.params?.assetId) {
            dispatch(
              showPopupFlag({
                appearance: "warning",
                title: "Unrecognised QR code",
              }),
            );
            return;
          }

          try {
            const assetId = parseInt(match.params.assetId);
            if (isNaN(assetId)) {
              dispatch(
                showPopupFlag({
                  appearance: "warning",
                  title: "Corrupted QR code",
                }),
              );
              return;
            }
            const item = items.find((item) => item.assetId === assetId);
            if (!item) {
              dispatch(
                showPopupFlag({
                  appearance: "info",
                  title: "Tool not in order",
                }),
              );
              return;
            }
            await dispatch(
              updateToolStoreOrderItem({
                ...item,
                toolStoreId: entity.toolStoreId,
                dateReadyForDelivery: item.dateReadyForDelivery ? null : dayjs().utc().toISOString(),
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: item.dateReadyForDelivery ? "Unmarked ready for delivery" : "Tool ready for delivery",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  if (!entity) {
    return <Loading />;
  }

  const itemsNotReady = items.filter((item) => !item.dateReadyForDelivery);
  const availableForReady = itemsNotReady.filter((item) => {
    const asset = assets[item.assetId];
    if (!asset) return false;
    return asset.fractional ? true : asset.assignments.length === 0;
  });
  const itemsReady = items.filter((item) => item.dateReadyForDelivery);
  return (
    <div className="flex flex-col gap-5">
      {entity.status === "accepted" && itemsNotReady.length === 0 && (
        <div className="text-center px-4 sm:px-6 mb-4">
          <h3 className="font-medium text-gray-900">Order ready to be delivered</h3>
          <p className="mt-1 text-sm text-gray-500">Confirm once the order is in transit.</p>
          <div className="mt-6">
            <PrimaryButton width="" loading={loading} onClick={confirmOrderInTransit}>
              Order in transit
            </PrimaryButton>
          </div>
        </div>
      )}

      {entity.status === "accepted" && itemsNotReady.length !== 0 && (
        <div>
          <div className="flex w-full gap-2 inline-flex items-center justify-between px-4 sm:px-6 mb-4">
            <span className="inline-block flex-1 font-medium text-gray-900">Items not ready</span>
            <div className="flex gap-2">
              <SecondaryButton onClick={scanQR}>Scan QR</SecondaryButton>
              {availableForReady.length !== 0 && (
                <SecondaryButton loading={loading} onClick={toggleAllReadyForDelivery}>
                  All ready
                </SecondaryButton>
              )}
            </div>
          </div>
          <ul className="flex-1 pl-0 divide-y divide-gray-200 border-b border-t border-gray-200">
            {itemsNotReady.map((item) => (
              <ToolStoreOrderSlideOverItem key={item.id} toolStoreOrderItemId={item.id} />
            ))}
          </ul>
        </div>
      )}

      {["pending", "in_transit", "delivered", "fulfilled"].indexOf(entity.status) !== -1 && (
        <div>
          <div className="flex w-full gap-2 inline-flex items-center justify-between px-4 sm:px-6 mb-4">
            <span className="inline-block flex-1 font-medium text-gray-900">Items ordered</span>
          </div>
          <ul className="flex-1 pl-0 divide-y divide-gray-200 border-b border-t border-gray-200">
            {items.map((item) => (
              <ToolStoreOrderSlideOverItem key={item.id} toolStoreOrderItemId={item.id} />
            ))}
          </ul>
        </div>
      )}

      {entity.status === "return_requested" && (
        <div>
          <div className="text-center px-4 sm:px-6 mb-10">
            <h3 className="font-medium text-gray-900">Items ready to be returned</h3>
            <p className="mt-1 text-sm text-gray-500">
              Confirm once <strong>{Resolve.resolveUserFullName(entity.createdByUserId)}</strong> has returned the items
              to you.
            </p>
            <div className="mt-6">
              <PrimaryButton width="" loading={loading} onClick={fulfilOrderReturned}>
                Confirm order returned
              </PrimaryButton>
            </div>
          </div>
          <div>
            <div className="flex w-full gap-2 inline-flex items-center justify-between px-4 sm:px-6 mb-4">
              <span className="inline-block flex-1 font-medium text-gray-900">Items being returned</span>
            </div>
            <ul className="flex-1 pl-0 divide-y divide-gray-200 border-b border-t border-gray-200">
              {items.map((item) => (
                <ToolStoreOrderSlideOverItem key={item.id} toolStoreOrderItemId={item.id} />
              ))}
            </ul>
          </div>
        </div>
      )}

      {entity.status === "accepted" && (
        <div>
          <div className="flex w-full gap-2 inline-flex items-center justify-between px-4 sm:px-6 mb-4">
            <span className="inline-block flex-1 font-medium text-gray-900">Items ready</span>
          </div>
          {itemsReady.length === 0 ? (
            <div className="text-center px-4 sm:px-6">
              <h3 className="font-medium text-gray-900">No items ready</h3>
              <p className="mt-1 text-sm text-gray-500">Once an item is ready to be delivered, mark it above.</p>
            </div>
          ) : (
            <ul className="flex-1 pl-0 divide-y divide-gray-200 border-b border-t border-gray-200">
              {itemsReady.map((item) => (
                <ToolStoreOrderSlideOverItem key={item.id} toolStoreOrderItemId={item.id} />
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
