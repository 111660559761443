import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchStorages,
  selectStoragesLoading,
  selectStoragesPage,
  selectStoragesPaging,
  updateStorage,
} from "store/reducers";
import { TLocation, TStorage } from "types";
import { SecondaryButton } from "components/Buttons";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { SmartTable, SmartTableNav, SmartTablePagination, TableLink } from "components/Table";
import { LocationSelector } from "components/inputs";
import { CreateStorageModal, StorageInventoryProgressBar, StorageStatusPill } from "components/shared";
import { StoragesTableRowDropdown } from "components/shared/features/storages";
import PageStage from "components/stages/PageStage";

type RowData = {
  row: {
    original: TStorage;
  };
};

export const ListStoragesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { pathname: url } = useLocation();
  const loading = useSelector(selectStoragesLoading);
  const storages = useSelector(selectStoragesPage);
  const paging = useSelector(selectStoragesPaging);
  const [query, setQuery] = useState<string>("");

  const rowData = storages;
  const columns = useMemo(() => {
    return [
      {
        Key: "Status",
        Header: "Status",
        Cell: ({ row }: RowData) => {
          return <StorageStatusPill storageId={row.original.id} />;
        },
      },
      {
        Key: "Name",
        Header: "Name",
        Cell: ({ row }: RowData) => {
          return <TableLink to={`/apps/storage/toolboxes/${row.original.id}`}>{row.original.name}</TableLink>;
        },
      },
      {
        Key: "Location",
        Header: "Location",
        Cell: ({ row }: RowData) => {
          return (
            <LocationSelector
              searchable
              appearance="inline"
              name="locationId"
              placeholder="Location"
              value={row.original.locationId}
              onChange={updateLocation(row.original)}
            />
          );
        },
      },
      {
        Key: "Inventory",
        Header: "Inventory",
        Cell: ({ row }: RowData) => {
          return <StorageInventoryProgressBar storageId={row.original.id} width="w-24" />;
        },
      },
      {
        id: "actions",
        Cell: ({ row }: RowData) => {
          return <StoragesTableRowDropdown storageId={row.original.id} onDelete={() => fetch(0, paging.pageLimit)} />;
        },
      },
    ];
  }, [paging]);

  useEffect(() => {
    fetch(0, paging.pageLimit);
  }, [query]);

  const fetch = (pageStart: number, pageLimit: number) => {
    dispatch(
      fetchStorages({
        pageStart,
        pageLimit,
        type: "toolbox",
        search_term: query || undefined,
      }),
    );
  };

  const createStorage = () => {
    openModal(
      <CreateStorageModal
        onSuccess={(storage: TStorage) => {
          navigate(`${url}/${storage.id}`);
        }}
      />,
    );
  };

  const updateLocation = (entity: TStorage) => {
    return async (name: string, value?: TLocation) => {
      try {
        await dispatch(
          updateStorage({
            ...entity,
            locationId: value?.id,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Storage location changed",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  return (
    <PageStage>
      <StageHeader
        title="Toolboxes"
        subtitle="Manage your tool storage by configuring inventory, access control, and settings."
        breadcrumb={["Storages", "Toolboxes"]}
        action={
          <Restrict capability={Capabilities.STORAGE_CREATE}>
            <SecondaryButton onClick={createStorage} icon="/images/icons/plus_1.svg">
              Create toolbox
            </SecondaryButton>
          </Restrict>
        }
      />
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by name or ID"
        onQueryChange={setQuery}
      />
      <SmartTable loading={loading} columns={columns} data={rowData} />
      <SmartTablePagination
        paging={paging}
        onChange={(pageStart: number, pageLimit: number) => fetch(pageStart, pageLimit)}
      />
    </PageStage>
  );
};
