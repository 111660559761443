import styled from "styled-components";
import { FixLater, SVGShape } from "types";
import { ToolSVG, ToolSVGAppearance } from "components/shared/features/svgs/ToolSVG/ToolSVG";
import styles from "./DrawerSVG.module.scss";

const GroupShape = styled.g<{ css: string }>`
  ${({ css }) => css};
`;

export const DrawerSVG = ({
  viewBox = "",
  shapes = [],
  value = [],
  drawerShape = null,
  groupShape = null,
  onClick,
}: FixLater) => {
  const renderShape = () => {
    return shapes.map((shape: SVGShape) => {
      const { id, asset, pulse = false } = shape;
      const isDrawer = id === drawerShape?.id;
      return (
        <ToolSVG
          key={id}
          onClick={!isDrawer ? () => onClick && onClick(shape) : undefined}
          selected={value.indexOf(id) !== -1}
          appearance={isDrawer ? ToolSVGAppearance.Drawer : ToolSVGAppearance.Tool}
          pulse={pulse}
          {...shape}
        />
      );
    });
  };

  const renderContent = () => {
    if (groupShape) {
      return (
        <GroupShape transform={groupShape.getAttribute("transform")} css={groupShape.getAttribute("style")}>
          {renderShape()}
        </GroupShape>
      );
    }
    return renderShape();
  };

  return (
    <svg viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg" className={styles.DrawerSVG}>
      {renderContent()}
    </svg>
  );
};
