import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useLazyTrackMixpanel } from "effects/useLazyTrackMixpanel";
import { Restricted } from "routes/Restricted";
import { requestLookups } from "lib/actions/lookupData";
import Apps from "lib/constants/Apps";
import { MixPanel } from "lib/mixpanel";
import { useDispatch, useSelector } from "store";
import { fetchLocations, selectAuthSession, selectMe } from "store/reducers";
import { AppSidebarLayout, ShopLayout } from "components/layouts";
import AuthLayout from "components/layouts/AuthLayout";
import EmptyLayout from "components/layouts/EmptyLayout";
import ManagerLayout from "components/layouts/ManagerLayout";
import { DoorwayPage, ListDoorwaysPage } from "components/shared/features/doorways";
import {
  AccountSettingsPage,
  AssetIssuesPage,
  CaseScannerPage,
  CaseScannersPage,
  ConfigurationPage,
  CreateToolStoreOrderPage,
  DashboardPage,
  ListAssetPage,
  ListAssetsPage,
  ListKeycardPage,
  ListKeycardsPage,
  ListUsersPage,
  LocationPage,
  LocationsPage,
  RagBinPage,
  RagControllerPage,
  RagControllersPage,
  ToolRoomPage,
  ToolRoomsPage,
  ToolStorePage,
  ToolStoresPage,
  ToolTrackingAlertPage,
  ToolTrackingAlertsPage,
  UserPage,
  VehiclesMapPage,
  VehiclesPage,
} from "pages";
import ComingSoon from "pages/ComingSoon";
import NotFound from "pages/NotFound";
import PermissionDenied from "pages/PermissionDenied";
import AccountMessagesContainer from "pages/account/AccountMessages/AccountMessagesContainer";
import StorageAlert from "pages/alerts/StorageAlert/StorageAlert";
import StorageAlerts from "pages/alerts/StorageAlerts/StorageAlerts";
import AccessControlDashboard from "pages/apps/AccessControl/Dashboard";
import AppsConfiguration from "pages/apps/AppsConfiguration";
import ComplianceDashboard from "pages/apps/Compliance/Dashboard";
import { ListProject } from "pages/apps/ProjectManagement/ListProject/ListProject";
import { ProjectsKanbanScreen } from "pages/apps/ProjectManagement/ProjectsKanbanScreen/ProjectsKanbanScreen";
import { ListTaskPage } from "pages/apps/ProjectManagement/tasks/ListTask/ListTaskPage";
import ShopDashboard from "pages/apps/Shop/Dashboard";
import { ShopCheckoutPage } from "pages/apps/Shop/ShopCheckoutPage/ShopCheckoutPage";
import ShopItemPage from "pages/apps/Shop/ShopItemPage/ShopItemPage";
import ShopManagerDashboard from "pages/apps/ShopManager/Dashboard";
import ShopImportPage from "pages/apps/ShopManager/ShopImportPage";
import ShopManagerItemPage from "pages/apps/ShopManager/ShopManageItemPage";
import WorkforceDashboard from "pages/apps/Workforce/Dashboard";
import { ChangePasswordPage, CompletePasswordRecoveryPage, LogoutPage } from "pages/authentication";
import ConfirmInvite from "pages/authentication/ConfirmInvite/ConfirmInvite";
import { LoginPage } from "pages/authentication/Login/LoginPage";
import RecoverPasswordPage from "pages/authentication/RecoverPassword/RecoverPasswordPage";
import SignupConfirmPage from "pages/authentication/Signup/SignupConfirmPage";
import SignupPage from "pages/authentication/Signup/SignupPage";
import CompanyBillingContainer from "pages/billing/CompanyBilling/CompanyBillingContainer";
import InvoicesContainer from "pages/billing/Invoices/InvoicesContainer";
import Company from "pages/company/Company/Company";
import {
  ConfigurationProductPage,
  ConfigureManufacturerPage,
  ListManufacturersPage,
  ListProductsPage,
  ListRolePage,
  ListRolesPage,
} from "pages/configurations";
import BrandingContainer from "pages/configurations/branding/Branding/BrandingContainer";
import ListEvidence from "pages/evidences/ListEvidence";
import MarketingPage from "pages/marketing/MarketingPage";
import PrivacyPolicyPage from "pages/marketing/PrivacyPolicyPage";
import TermsOfUsePage from "pages/marketing/TermsOfUsePage";
import CompanyPrivacyContainer from "pages/privacy/CompanyPrivacy/CompanyPrivacyContainer";
import ListReaderContainer from "pages/readers/ListReader/ListReaderContainer";
import ListReaders from "pages/readers/ListReaders/ListReaders";
import RegisterReaderContainer from "pages/readers/RegisterReader/RegisterReaderContainer";
import ListReports from "pages/reporting/reports/ListReports/ListReports";
import ListReportContainer from "pages/reporting/reports/PublicReportPage/ListReportContainer";
import ReportPage from "pages/reporting/reports/ReportPage";
import { ListStoragesPage, ListStoragePage } from "pages/storages";

export const AppRouter = () => {
  const dispatch = useDispatch();
  const session = useSelector(selectAuthSession);
  const me = useSelector(selectMe);
  const { pathname: url } = useLocation();
  const isRootDomain = ["www.automify.co.uk"].indexOf(window.location.hostname) !== -1;
  const track = useLazyTrackMixpanel();

  useEffect(() => {
    track("View Page", {
      pathname: url,
    });
  }, [track, url]);

  useEffect(() => {
    if (!session) return;
    dispatch(requestLookups());
    dispatch(fetchLocations({ pageLimit: 999 }));
  }, [session]);

  useEffect(() => {
    if (!me) return;
    MixPanel.identify(me.guid);
  }, [me]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {!isRootDomain && <Route path="/" element={<LoginPage />} />}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/recover_password" element={<RecoverPasswordPage />} />
        <Route path="/complete_password_recovery" element={<CompletePasswordRecoveryPage />} />
        <Route path="/accept_invite" element={<ConfirmInvite />} />

        <Route element={<Restricted />}>
          <Route path="/change_password" element={<ChangePasswordPage />} />
        </Route>
      </Route>

      <Route element={<AuthLayout useBranding={false} />}>
        <Route path="/signup_confirm" element={<SignupConfirmPage />} />
      </Route>

      <Route element={<AppSidebarLayout />}>
        <Route element={<Restricted />}>
          <Route element={<ShopLayout />}>
            <Route path="/apps/shop" element={<ShopDashboard />} />
            <Route path="/apps/shop/items/:itemID" element={<ShopItemPage />} />
            <Route path="/apps/shop/checkout/:section" element={<ShopCheckoutPage />} />
            <Route path="/apps/shop/checkout" element={<Navigate replace to={`${url}/cart`} />} />
          </Route>
          <Route element={<ManagerLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/account/settings" element={<AccountSettingsPage />} />
            <Route path="/account/messages" element={<AccountMessagesContainer />} />

            <Route element={<Restricted app={Apps.TOOL_TRACKING} />}>
              <Route path="/apps/tracking/assets" element={<ListAssetsPage />} />
              <Route path="/apps/tracking/settings" element={<ComingSoon />} />
              <Route path="/apps/tracking/issues" element={<AssetIssuesPage />} />
              <Route path="/apps/tracking/alerts*" element={<ToolTrackingAlertsPage />} />
              <Route path="/apps/tracking/alerts/:alertID" element={<ToolTrackingAlertPage />} />
              <Route path="/apps/tracking/assets/:assetID/:section" element={<ListAssetPage />} />
              <Route path="/apps/tracking/assets/:assetID" element={<Navigate replace to={`${url}/summary`} />} />
              <Route path="/apps/tracking" element={<Navigate replace to={`${url}/assets`} />} />
            </Route>

            <Route element={<Restricted app={Apps.VEHICLES} />}>
              <Route path="/apps/vehicles">
                <Route path="map" element={<VehiclesMapPage />} />
                <Route path="manage">
                  <Route index element={<VehiclesPage />} />
                  <Route path=":vehicleId" element={<div />} />
                  <Route path="*" element={<Navigate replace to="/apps/vehicles/manage" />} />
                </Route>
                <Route index element={<Navigate replace to="/apps/vehicles/map" />} />
                <Route path="*" element={<Navigate replace to="/apps/vehicles" />} />
              </Route>
            </Route>

            <Route element={<Restricted app={Apps.PROJECT_TRACKING} />}>
              <Route path="/apps/projects" element={<ProjectsKanbanScreen />} />
              <Route path="/apps/projects/:projectID" element={<ListProject />} />
              <Route path="/apps/projects/:projectID/tasks/:taskID" element={<ListTaskPage />} />
            </Route>

            <Route element={<Restricted app={Apps.TOOL_SHOP_MANAGEMENT} />}>
              <Route path="/apps/shop_manager" element={<ShopManagerDashboard />} />
              <Route path="/apps/shop_manager/import" element={<ShopImportPage />} />
              <Route path="/apps/shop_manager/items/:itemID/:section" element={<ShopManagerItemPage />} />
              <Route path="/apps/shop_manager/items/:itemID" element={<Navigate replace to={`${url}/dashboard`} />} />
            </Route>

            <Route element={<Restricted app={Apps.WORKFORCE} />}>
              <Route path="/apps/workforce" element={<WorkforceDashboard />} />
            </Route>

            <Route element={<Restricted app={Apps.REGULATORY_COMPLIANCE} />}>
              <Route path="/apps/compliance" element={<ComplianceDashboard />} />
            </Route>

            <Route element={<Restricted app={Apps.ACCESS_CONTROL} />}>
              <Route path="/apps/access_control" element={<AccessControlDashboard />} />
            </Route>

            <Route element={<Restricted app={Apps.TOOL_STORAGE} />}>
              <Route path="/apps/storage/toolboxes/:storageID*" element={<ListStoragePage />} />
              <Route path="/apps/storage/toolboxes*" element={<ListStoragesPage />} />

              <Route element={<Restricted app={Apps.TOOL_STORES} />}>
                <Route path="/apps/storage/stores/:toolStoreID*" element={<ToolStorePage />} />
                <Route path="/apps/storage/stores" element={<ToolStoresPage />} />
              </Route>

              <Route element={<Restricted app={Apps.TOOL_CASE_TRACKING} />}>
                <Route path="/apps/storage/case_scanners/:caseScannerID*" element={<CaseScannerPage />} />
                <Route path="/apps/storage/case_scanners" element={<CaseScannersPage />} />
              </Route>

              <Route element={<Restricted app={Apps.TOOL_ROOMS} />}>
                <Route path="/apps/storage/tool_rooms/:toolRoomID*" element={<ToolRoomPage />} />
                <Route path="/apps/storage/tool_rooms" element={<ToolRoomsPage />} />
              </Route>

              <Route element={<Restricted app={Apps.RAG_CONTROL} />}>
                <Route path="/apps/storage/rag_controllers/:ragControllerID/bins/:ragBinID*" element={<RagBinPage />} />
                <Route path="/apps/storage/rag_controllers/:ragControllerID*" element={<RagControllerPage />} />
                <Route path="/apps/storage/rag_controllers" element={<RagControllersPage />} />
              </Route>

              <Route element={<Restricted app={Apps.DOORWAYS} />}>
                <Route path="/apps/tracking/doorways/:doorwayID*" element={<DoorwayPage />} />
                <Route path="/apps/tracking/doorways" element={<ListDoorwaysPage />} />
              </Route>

              <Route path="/apps/storage/alerts*" element={<StorageAlerts />} />
              <Route path="/apps/storage*" element={<Navigate replace to="/apps/storage/toolboxes" />} />
            </Route>

            <Route path="/apps/storage/alerts/:alertID" element={<StorageAlert />} />
            <Route path="/configuration" element={<ConfigurationPage />} />
            <Route path="/configuration/users" element={<ListUsersPage />} />
            <Route path="/configuration/users/:id" element={<UserPage />} />
            <Route path="/configuration/locations" element={<LocationsPage />} />
            <Route path="/configuration/locations/:id" element={<LocationPage />} />
            <Route path="/configuration/readers" element={<ListReaders />} />
            <Route path="/configuration/readers/register" element={<RegisterReaderContainer />} />
            <Route path="/configuration/readers/:id" element={<ListReaderContainer />} />
            <Route path="/configuration/branding" element={<BrandingContainer />} />
            <Route path="/configuration/apps" element={<AppsConfiguration />} />
            <Route path="/configuration/roles" element={<ListRolesPage />} />
            <Route path="/configuration/roles/:roleID" element={<ListRolePage />} />
            <Route path="/configuration/keycards" element={<ListKeycardsPage />} />
            <Route path="/configuration/keycards/:keycardID" element={<ListKeycardPage />} />
            <Route path="/configuration/products" element={<ListProductsPage />} />
            <Route path="/configuration/products/:productID" element={<ConfigurationProductPage />} />
            <Route path="/configuration/manufacturers" element={<ListManufacturersPage />} />
            <Route path="/configuration/manufacturers/:manufacturerID" element={<ConfigureManufacturerPage />} />

            <Route element={<Restricted app={Apps.REPORTING} />}>
              <Route path="/apps/reporting" element={<ListReports />} />
              <Route path="/apps/reporting/reports/:reportID/:section" element={<ReportPage />} />
              <Route path="/apps/reporting/reports/:reportID" element={<Navigate replace to={`${url}/exports`} />} />
            </Route>

            <Route path="/company" element={<Company />} />
            <Route path="/company/billing/settings" element={<CompanyBillingContainer />} />
            <Route path="/company/billing/invoices" element={<InvoicesContainer />} />
            <Route path="/company/privacy" element={<CompanyPrivacyContainer />} />
          </Route>
        </Route>
      </Route>

      <Route element={<Restricted />}>
        <Route element={<EmptyLayout />}>
          <Route path="/toolstore*" element={<CreateToolStoreOrderPage />} />
        </Route>
      </Route>

      {/*<Route path="public" element={<EmptyLayout />}>*/}
      {/*  <Route path="apps/vehicles">*/}
      {/*    <Route path="dashboard/:dashboardId" element={<VehiclesMapPage />} />*/}
      {/*    <Route path="" element={<NotFound />} />*/}
      {/*  </Route>*/}
      {/*</Route>*/}

      <Route element={<EmptyLayout />}>
        <Route path="/terms-and-conditions" element={<TermsOfUsePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path={isRootDomain ? "/" : "/marketing"} element={<MarketingPage />} />
        <Route path="/evidences/:evidenceId" element={<ListEvidence />} />
        <Route path="/apps/reporting/public/reports/:reportID/:section" element={<ListReportContainer />} />
        <Route path="/restricted" element={<PermissionDenied />} />
        <Route element={<NotFound />} />
      </Route>
    </Routes>
  );
};
