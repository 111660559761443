//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "effects";
import PropTypes from "prop-types";
import { showPopupFlag } from "lib/actions/userInterface";
import Api from "lib/api/assets";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { DangerButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { Paragraph } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";

type AssetSettingsProps = {
  assetId: number;
};

const AssetSettings = ({ assetId }: AssetSettingsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const removeAsset = () => {
    openModal(
      <ConfirmModal
        actionText="Delete tool"
        description="Are you sure you want to delete this tool? The entire tool history will been permanently deleted."
        onConfirm={async () => {
          try {
            await Api.deleteAsset(assetId);
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Tool has been removed",
              }),
            );
            navigate("/apps/tracking/assets", { replace: true });
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  return (
    <>
      <StageHeader title="Settings" />
      <FieldGroup label="Delete tool">
        <Paragraph className="text-muted">
          Warning: This action cannot be undone and will permanently delete all data associated with the tool.
        </Paragraph>
        <div>
          <DangerButton onClick={removeAsset}>Permanently delete</DangerButton>
        </div>
      </FieldGroup>
    </>
  );
};

AssetSettings.defaultProps = {};

AssetSettings.propTypes = {
  asset: PropTypes.object,
};

export default AssetSettings;
