import { FormEvent, useEffect, useState } from "react";
import { useModal } from "effects";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { runSampleProximitySensors } from "store/reducers";
import { TProximitySensorReport } from "types/features";
import { NumberInput } from "components/Input";
import Loading from "components/Loading";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import {
  StorageDrawerSensorSettings,
  processProximityDrawerSensorReports,
  findDrawerOpenedFromProximitySensorReportValues,
  calculateDrawerProximitySettings,
  ProcessedDrawerProximitySensorValue,
} from "components/shared/features/storages/utils/proximity.utility";
import { HSpace, VSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";
import { TextBody } from "components/shared/typography";

const StyledModal = styled(Modal)`
  min-width: 30rem;
`;

type ScanToolSensorModalProps = {
  storageId: string;
  drawerName: string;
  onSuccess: (settings: StorageDrawerSensorSettings) => void;
};

export const ScanDrawerSensorModal = ({ storageId, drawerName, onSuccess }: ScanToolSensorModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [fetching, setFetching] = useState<boolean>(false);
  const [sensorIssue, setSensorIssue] = useState<boolean>(false);
  const [cleanSensorReport, setCleanSensorReport] = useState<TProximitySensorReport[]>();
  const [dirtySensorReport, setDirtySensorReport] = useState<TProximitySensorReport[]>();
  const [cleanSensor, setCleanSensor] = useState<ProcessedDrawerProximitySensorValue>();
  const [dirtySensor, setDirtySensor] = useState<ProcessedDrawerProximitySensorValue>();
  const [settings, setSettings] = useState<StorageDrawerSensorSettings>();

  const fetchReports = async (): Promise<TProximitySensorReport[]> => {
    try {
      setFetching(true);
      return await dispatch(
        runSampleProximitySensors({
          storageId,
        }),
      ).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    } finally {
      setFetching(false);
    }
  };

  const fetchDirtyReport = async () => {
    const result = await fetchReports();
    if (result.length) {
      setDirtySensorReport(result);
    }
  };

  const fetchCleanReport = async () => {
    const result = await fetchReports();
    if (result.length) {
      setCleanSensorReport(result);
    }
  };

  useEffect(() => {
    if (cleanSensorReport && dirtySensorReport) {
      const processedClean = processProximityDrawerSensorReports(cleanSensorReport);
      const processedDirty = processProximityDrawerSensorReports(dirtySensorReport);
      const { clean, dirty } = findDrawerOpenedFromProximitySensorReportValues(processedClean, processedDirty);
      if (clean && dirty) {
        setCleanSensor(clean);
        setDirtySensor(dirty);
        setSettings(calculateDrawerProximitySettings(clean));
      } else {
        // Potentially detected a dead sensor
        if (!clean && !dirty) {
          setSensorIssue(true);
          return;
        }
        dispatch(
          showPopupFlag({
            appearance: "warning",
            title: "Something went wrong",
            description: clean
              ? "No sensor readings available when drawer was open "
              : "No sensor readings available when drawer was closed",
          }),
        );
        console.error("failed to find asset taken from clean or dirty reports", clean, dirty);
      }
    }
  }, [cleanSensorReport, dirtySensorReport]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!settings) return;
      closeModal();
      onSuccess(settings);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (fetching) {
    return (
      <StyledModal>
        <ModalHeader title="Scanning sensors" className="text-center">
          <TextBody textColor="text-gray-500">This will take 5 seconds...</TextBody>
        </ModalHeader>
        <div className="flex h-24 justify-center items-center">
          <Loading />
        </div>
      </StyledModal>
    );
  }

  if (sensorIssue) {
    return (
      <StyledModal>
        <ModalHeader title="⚠️ Sensor issue" className="text-center">
          <TextBody textColor="text-gray-500">
            Failed to read the sensor, please check the sensor and try again.{" "}
          </TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <ModalPrimaryActionButton onClick={closeModal}>Okay</ModalPrimaryActionButton>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  if (!cleanSensorReport) {
    return (
      <StyledModal>
        <ModalHeader title="Check the drawer is closed" className="text-center">
          <TextBody textColor="text-gray-500">
            Check that the drawer <strong>{drawerName}</strong> is closed then click continue below.
          </TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <HSpace>
              <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
              <ModalPrimaryActionButton onClick={fetchCleanReport}>Continue</ModalPrimaryActionButton>
            </HSpace>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  if (!dirtySensorReport) {
    return (
      <StyledModal>
        <ModalHeader title="Open the drawer" className="text-center">
          <TextBody textColor="text-gray-500">
            Once drawer <strong>{drawerName}</strong> is open, click continue below.
          </TextBody>
        </ModalHeader>
        <ModalContent>
          <ModalContent>
            <HSpace>
              <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
              <ModalPrimaryActionButton onClick={fetchDirtyReport}>Continue</ModalPrimaryActionButton>
            </HSpace>
          </ModalContent>
        </ModalContent>
      </StyledModal>
    );
  }

  return (
    <StyledModal>
      <ModalHeader title="We found the sensor" className="text-center">
        <TextBody textColor="text-gray-500">
          Check the settings we found for the sensor and adjust if necessary.
        </TextBody>
      </ModalHeader>
      <ModalContent>
        {settings && (
          <VSpace>
            <FieldGroup label="Threshold">
              {cleanSensor && dirtySensor && (
                <TextBody textColor="text-gray-400">
                  Detected <span className="text-gray-600">{Math.floor(cleanSensor.drawerValue)}</span> when the drawer
                  was closed and <span className="text-gray-600">{Math.floor(dirtySensor.drawerValue)}</span> when
                  opened.
                </TextBody>
              )}
              {settings && (
                <NumberInput
                  showKeyboard
                  input={{
                    name: "irSensorThreshold",
                    placeholder: "Enter threshold",
                  }}
                  value={settings.irSensorThreshold}
                  onChange={(name: string, value: number) =>
                    setSettings((prevState) => ({
                      ...prevState,
                      irSensorThreshold: value,
                    }))
                  }
                />
              )}
            </FieldGroup>
            <FieldGroup label="Sensor board">
              <NumberInput
                showKeyboard
                input={{
                  name: "irSensorBoardId",
                  placeholder: "Enter sensor board number",
                }}
                value={settings?.irSensorBoardId}
                onChange={(name: string, value: number) =>
                  setSettings((prevState) => ({
                    ...prevState,
                    irSensorBoardId: value,
                  }))
                }
              />
            </FieldGroup>
          </VSpace>
        )}
        <HSpace>
          <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton onClick={handleSubmit}>Apply settings</ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </StyledModal>
  );
};
