import styled from "styled-components";
import { useSelector } from "store";
import { selectStorageByID } from "store/reducers";
import { TailwindWidth } from "types/tailwind";
import { ProgressBar } from "components/shared/charts";
import { TextBody } from "components/shared/typography";

const InventoryProgress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.25rem;
`;

type StorageInventoryProgressBarProps = {
  storageId: string;
  threshold?: number;
  summarise?: boolean; // Whether to show exactly number of tools missing.
  width?: TailwindWidth;
};

export const StorageInventoryProgressBar = ({
  storageId,
  width = "w-full",
  summarise = false,
  threshold = 0.4,
}: StorageInventoryProgressBarProps) => {
  const entity = useSelector(selectStorageByID(storageId));
  if (!entity?.assetsMetric) return null;

  const { total, taken, available } = entity.assetsMetric;
  const availabilityProgress = total > 0 ? available / total : 0;

  return (
    <InventoryProgress>
      <TextBody className="whitespace-nowrap">
        {summarise ? (
          <span>{taken} tools outstanding</span>
        ) : (
          <span>
            {available}
            <span className="text-slate-400 text-sm">&nbsp;/&nbsp;{total} tools</span>
          </span>
        )}
      </TextBody>
      <ProgressBar
        width={width}
        backgroundColor={availabilityProgress < threshold ? "bg-amber-400" : "bg-green-500"}
        value={availabilityProgress}
      />
    </InventoryProgress>
  );
};
