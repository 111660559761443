//@ts-nocheck
import React from "react";
import withAssets from "hocs/withAssets";
import PropTypes from "prop-types";
import { AssetHistory } from "./AssetHistory";

class AssetHistoryContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestAssetHistory: PropTypes.func,
    }),
    asset: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fetchHistory(0, 50);
  }

  fetchHistory = (pageStart = 0, pageLimit = 50) => {
    const { requestAssetHistory } = this.props.actions;
    const { id } = this.props.asset;
    requestAssetHistory(id, { pageStart, pageLimit });
  };

  handlePrevPage = () => {
    const { previousPageStart, pageLimit } = this.props.stateStore.history.paging;
    this.fetchHistory(previousPageStart, pageLimit);
  };

  handleNextPage = () => {
    const { nextPageStart, pageLimit } = this.props.stateStore.history.paging;
    this.fetchHistory(nextPageStart, pageLimit);
  };

  handleLimitChange = (pageLimit) => {
    this.fetchHistory(0, pageLimit);
  };

  render() {
    const { data: events, paging } = this.props.stateStore.history;
    return (
      <AssetHistory
        events={events}
        paging={paging}
        handlePrevPage={this.handlePrevPage}
        handleNextPage={this.handleNextPage}
        handleLimitChange={this.handleLimitChange}
      />
    );
  }
}

export default withAssets(AssetHistoryContainer);
