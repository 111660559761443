import { useState } from "react";
import Assets from "lib/api/assets";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssetById, selectAssetByGUID } from "store/reducers";
import { TUser, UUID } from "types";
import { DataItemGroup } from "components/DataGrid";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import UsersInput from "components/inputs/UsersInput";
import { VSpace } from "components/shared";
import { WhiteButton } from "components/shared/buttons";

type CreateAssetAssignmentDataItemProps = {
  assetId: UUID;
};

export const CreateAssetAssignmentDataItem = ({ assetId }: CreateAssetAssignmentDataItemProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByGUID(assetId));
  const [creating, setCreating] = useState<boolean>(false);
  const [user, setUser] = useState<TUser>();

  const createAssignment = async () => {
    try {
      if (!user) return;
      if (!asset) return;
      setCreating(true);
      await Assets.createAssetAssignment(asset.id, user.id);
      await dispatch(fetchAssetById(asset.id)).unwrap();
      setUser(undefined);
      setCreating(false);
    } catch (err) {
      handlePopupFlagError(err);
      setCreating(false);
    }
  };

  if (!asset) {
    return null;
  }

  // TODO: Make the form look pretty or move it into a modal.
  return (
    <DataItemGroup>
      <VSpace>
        <FieldGroup label="Manually assign tool">
          <UsersInput name="user" value={user} onChange={(_: string, value: TUser) => setUser(value)} />
          {user && (
            <WhiteButton onClick={createAssignment} disabled={creating || !user}>
              Assign tool
            </WhiteButton>
          )}
        </FieldGroup>
      </VSpace>
    </DataItemGroup>
  );
};
