//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "effects";
import moment from "moment";
import Resolve from "lib/Resolve";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import Capabilities from "lib/constants/Capabilities";
import { fetchCalibrationCertificates, selectAssetByID } from "store/reducers";
import { TCalibrationConfig } from "types";
import { ButtonGroup, PrimaryButton, SecondaryButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import Loading from "components/Loading";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import { Paragraph, Subheader } from "components/Typography";
import { CalibrationConfigureModal, TextBody, VSpace } from "components/shared";
import { CreateCalibrationEventModal } from "components/shared/features/calibrationCertificates/CreateCalibrationCertificateModal/CreateCalibrationCertificateModal";
import { CalibrationEventsTable } from "pages/assets/ListAsset/components/AssetCalibration/CalibrationEventsTable";

const CalibrationInterval = ({ config }) => {
  switch (config.methodId) {
    case CalibrationTrackingMethods.TIME:
      return <span>Every {config.timeInterval}</span>;
    case CalibrationTrackingMethods.ACTUATION:
      return <span>{config.actuationInterval} actuations</span>;
    case CalibrationTrackingMethods.TIME_AND_ACTUATION:
      return (
        <span>
          A calendar {config.timeInterval} or {config.actuationInterval} actuations
        </span>
      );
    default:
      return null;
  }
};

const NextCalibration = ({ config }: { config: TCalibrationConfig }) => {
  switch (config.methodId) {
    case CalibrationTrackingMethods.TIME:
      return <span>{moment(config.nextDue).format("Do MMMM YYYY")}</span>;
    case CalibrationTrackingMethods.ACTUATION:
      return <span>{config.actuationInterval - config.actuationCount} actuations</span>;
    case CalibrationTrackingMethods.TIME_AND_ACTUATION:
      return (
        <span>
          {moment(config.nextDue).format("Do MMMM YYYY")} or in {config.actuationInterval - config.actuationCount}{" "}
          actuations
        </span>
      );
    default:
      return null;
  }
};

type AssetCalibrationProps = {
  assetId: number;
};

const AssetCalibration = ({ assetId }: AssetCalibrationProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const asset = useSelector(selectAssetByID(assetId));
  const calibration = asset?.calibrationConfig;

  if (!asset) {
    return <Loading />;
  }

  const openManageConfiguration = () => {
    if (!asset) return;
    openModal(
      <CalibrationConfigureModal
        assetId={asset.guid}
        onSuccess={() => {
          // TODO: Anything?
        }}
      />,
    );
  };

  const submitCertificate = () => {
    if (!asset) return;
    openModal(
      <CreateCalibrationEventModal
        assetId={asset.guid}
        onSuccess={() => {
          dispatch(
            fetchCalibrationCertificates({
              assetId: asset.guid,
              pageLimit: 999,
            }),
          );
        }}
      />,
    );
  };

  const renderForm = () => {
    switch (calibration.methodId) {
      case CalibrationTrackingMethods.ACTUATION:
      case CalibrationTrackingMethods.TIME:
      case CalibrationTrackingMethods.TIME_AND_ACTUATION:
        return (
          <>
            <DataItem label="Interval" value={<CalibrationInterval config={calibration} />} />
            <DataItem label="Next due" value={<NextCalibration config={calibration} />} />
          </>
        );
    }
  };

  return (
    <>
      <StageHeader
        title="Calibration tracking"
        action={
          calibration && (
            <Restrict capability={Capabilities.ASSET_UPDATE}>
              <ButtonGroup>
                <SecondaryButton onClick={openManageConfiguration} icon="/images/icons/edit.svg">
                  Edit configuration
                </SecondaryButton>
                <PrimaryButton onClick={submitCertificate}>Submit calibration certificate</PrimaryButton>
              </ButtonGroup>
            </Restrict>
          )
        }
      />
      <StageSection isFirst>
        <VSpace>
          {!calibration && (
            <>
              <TextBody>This tool is not configured for calibration tracking.</TextBody>
              <div>
                <PrimaryButton onClick={openManageConfiguration}>Enable calibration tracking</PrimaryButton>
              </div>
            </>
          )}
          {calibration && (
            <>
              <Subheader>Configuration</Subheader>
              <DataGrid>
                <DataItem
                  label="Tracking method"
                  value={Resolve.resolveCalibrationTrackingMethodName(calibration.methodId)}
                />
                {renderForm()}
              </DataGrid>
            </>
          )}
        </VSpace>
      </StageSection>
      {calibration && (
        <StageSection>
          <VSpace>
            <Subheader>Certificates</Subheader>
            <Paragraph>Browse through all the known calibration certificates for this tool</Paragraph>
            <CalibrationEventsTable assetId={asset.guid} />
          </VSpace>
        </StageSection>
      )}
    </>
  );
};

export default AssetCalibration;
