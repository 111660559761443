import { useEffect, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchAssetById,
  selectAssetByID,
  selectToolStoreOrderByID,
  selectToolStoreOrderItemByID,
  updateToolStoreOrderItem,
} from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { TableLink } from "components/Table/TableLink/TableLink";
import { Text } from "components/Typography";
import { AssetSlideOverOptionsMenu } from "components/shared/features/assets";
import { HSpace } from "components/shared/layouts";

type ToolStoreOrderSlideOverItemProps = {
  toolStoreOrderItemId: UUID;
};

export const ToolStoreOrderSlideOverItem = ({ toolStoreOrderItemId }: ToolStoreOrderSlideOverItemProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const entity = useSelector(selectToolStoreOrderItemByID(toolStoreOrderItemId));
  const asset = useSelector(selectAssetByID(entity?.assetId));
  const toolStoreOrder = useSelector(selectToolStoreOrderByID(entity?.toolStoreOrderId));
  const assigned = asset?.assignments.length !== 0;

  useEffect(() => {
    if (entity && !asset) {
      dispatch(fetchAssetById(entity.assetId));
    }
  }, [entity, asset]);

  if (!entity || !asset || !toolStoreOrder) {
    return (
      <li className="py-6 px-5">
        <Loading />
      </li>
    );
  }

  const toggleDateReadyForDelivery = async () => {
    try {
      setLoading(true);
      await dispatch(
        updateToolStoreOrderItem({
          ...entity,
          toolStoreId: toolStoreOrder.toolStoreId,
          dateReadyForDelivery: entity.dateReadyForDelivery ? null : dayjs().utc().toISOString(),
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: entity.dateReadyForDelivery ? "Unmarked ready for delivery" : "Tool marked ready for delivery",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  return (
    <li>
      <div className="group relative flex items-center py-6 px-5">
        <div className="-m-1 block flex-1 p-1">
          <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
          <div className="relative flex min-w-0 flex-1 items-center">
            {toolStoreOrder.status === "accepted" && !(!asset?.fractional && assigned) && (
              <span className="relative inline-block flex-shrink-0">
                <div className="relative w-10">
                  {entity.dateReadyForDelivery && <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />}
                  {loading ? (
                    <Loading />
                  ) : (
                    <input
                      type="checkbox"
                      className="absolute top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      value={entity.dateReadyForDelivery || undefined}
                      checked={!!entity.dateReadyForDelivery}
                      onChange={toggleDateReadyForDelivery}
                    />
                  )}
                </div>
              </span>
            )}
            <Popover
              preferPlace="above"
              content={<Text>{assigned ? "Tool unavailable" : "Tool available"}</Text>}
              className="flex-shrink-0"
            >
              <span className="relative inline-block">
                <img
                  className="h-10 w-10 rounded-full object-cover"
                  src={asset.catalogProduct?.imageUrl}
                  alt={asset.catalogProduct?.name}
                />
                <span
                  className={classNames(
                    assigned ? "bg-red-500" : "bg-green-400",
                    "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white",
                  )}
                  aria-hidden="true"
                />
              </span>
            </Popover>
            <div className="ml-4 flex-1 pr-4">
              <p className="text-sm whitespace-normal font-medium text-gray-900">{asset.catalogProduct?.name}</p>
              {asset.assignments.length !== 0 ? (
                <p className="text-sm text-gray-500">
                  Currently assigned to:
                  <br />
                  <HSpace gap="gap-0">
                    {asset.assignments.map((assignment) => (
                      <TableLink to={`/configuration/users/${assignment.userId}`} target="_blank">
                        {Resolve.resolveUserFullName(assignment.userId)}
                      </TableLink>
                    ))}
                  </HSpace>
                </p>
              ) : (
                <p className="text-sm text-gray-500">{asset.serialNumber}</p>
              )}
            </div>
          </div>
        </div>
        <AssetSlideOverOptionsMenu assetId={asset.id} />
      </div>
    </li>
  );
};
