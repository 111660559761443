import React from "react";
import { Menu } from "@headlessui/react";
import Popover from "components/Popover";
import { Text } from "components/Typography";

type DropdownMenuItemProps = {
  label: string;
  icon: any;
  onClick?: () => void;
  disabled?: boolean;
  disabledReason?: string;
};

export type DropdownMenuItemAction = DropdownMenuItemProps | boolean | undefined;

export const DropdownMenuItem = ({
  icon: Icon,
  label,
  onClick,
  disabled = false,
  disabledReason = "",
}: DropdownMenuItemProps) => {
  const renderButton = (active: boolean) => {
    return (
      <button
        className={`${
          disabled ? "text-gray-400" : active ? "bg-gray-100 text-gray-900" : "text-gray-700"
        } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
        onClick={!disabled ? onClick : undefined}
      >
        <Icon className="w-5 h-5 mr-2" aria-hidden="true" />
        {label}
      </button>
    );
  };

  return (
    <Menu.Item>
      {({ active }) =>
        disabled && disabledReason ? (
          <Popover preferPlace="above" content={<Text>{disabledReason}</Text>}>
            {renderButton(active)}
          </Popover>
        ) : (
          renderButton(active)
        )
      }
    </Menu.Item>
  );
};
