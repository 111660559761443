import { RiSignalTowerFill } from "react-icons/ri";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import Apps from "lib/constants/Apps";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID, updateAsset } from "store/reducers";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import Text from "components/Typography/Text";
import { PowerInput } from "components/inputs/PowerInput";
import { DetectAssetRFIDTagsModal, PageLoading, VSpace } from "components/shared";
import { SecondaryButton } from "components/shared/buttons";
import { AssetRFIDConfig } from "components/shared/features/assets/AssetRFIDConfig/AssetRFIDConfig";
import { CreateAssetAssignmentDataItem } from "pages/assets/ListAsset/components/AssetTracking/components/CreateAssetAssignmentDataItem";
import { AssetAssignmentDataItem } from "./components/AssetAssignmentDataItem";

type AssetTrackingProps = {
  assetId: number;
};

export const AssetTracking = ({ assetId }: AssetTrackingProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const { openModal } = useModal();

  const onChange = async (name: string, value: any) => {
    if (!asset) return;
    try {
      await dispatch(
        updateAsset({
          ...asset,
          [name]: value,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Tracking updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleEpcsDetected = (epcs: string[]) => {
    if (!asset) return;
    onChange("rfidTagId", Array.from(new Set([...asset.rfidTagId, ...epcs])));
  };

  const handleDetectUsingDevice = () => {
    openModal(<DetectAssetRFIDTagsModal onSuccess={handleEpcsDetected} />);
  };

  if (!asset) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader title="Tool tracking" />
      <StageSection isFirst>
        <DataGrid>
          <DataItem label={`Tool assignment${asset.fractional ? "s" : ""}`}>
            <VSpace>
              {(asset.assignments.length === 0 || asset.fractional) && (
                <CreateAssetAssignmentDataItem assetId={asset.guid} />
              )}
              {asset.assignments.length !== 0 && (
                <DataItemGroup>
                  <div className="grid grid-cols-1 divide-y">
                    {asset.assignments.map((assignment) => (
                      <AssetAssignmentDataItem key={assignment.id} assignment={assignment} />
                    ))}
                  </div>
                </DataItemGroup>
              )}
            </VSpace>
          </DataItem>

          <DataItem
            label="Configuration"
            value={
              <DataItemGroup>
                <VSpace>
                  <DataItem
                    label="Multi-piece"
                    description="This is a multi-piece tool"
                    value={<PowerInput value={asset.fractional} onChange={(value) => onChange("fractional", value)} />}
                  />
                  {asset.fractional && (
                    <DataItem
                      label="Number of pieces (optional)"
                      value={
                        <Text
                          canEdit
                          input={{ type: "number", min: 1, step: 1 }}
                          value={asset.fractions}
                          placeholder="Enter a number 1 or greater"
                          onConfirm={(value: number) => onChange("fractions", value)}
                        />
                      }
                    />
                  )}
                  <Restrict app={Apps.TOOL_TRACKING_RFID}>
                    <DataItem
                      label="RFID"
                      description="Use RFID to track this tool"
                      value={
                        <PowerInput value={asset.rfidTracking} onChange={(value) => onChange("rfidTracking", value)} />
                      }
                    />
                    {asset.rfidTracking && (
                      <DataItem
                        label="RFID tags"
                        value={
                          <VSpace gap="gap-2">
                            <SecondaryButton leadingIcon={RiSignalTowerFill} onClick={handleDetectUsingDevice}>
                              Scan with device
                            </SecondaryButton>
                            <AssetRFIDConfig epcs={asset.rfidTagId} onChange={(epcs) => onChange("rfidTagId", epcs)} />
                          </VSpace>
                        }
                      />
                    )}
                  </Restrict>
                </VSpace>
              </DataItemGroup>
            }
          />
        </DataGrid>
      </StageSection>
    </>
  );
};
