import { HiOutlineCheckCircle, HiOutlineFlag, HiOutlineUserAdd, HiOutlineUserRemove } from "react-icons/hi";
import { Menu } from "@headlessui/react";
import { DotsVerticalIcon, TrashIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteAssetIssue, selectAssetIssueByID, selectMe, updateAssetIssue } from "store/reducers";
import { UUID } from "types";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type AssetIssueTableRowDropdownProps = {
  assetIssueId: UUID;
  onDelete?: () => void;
};

export const AssetIssueTableRowDropdown = ({ assetIssueId, onDelete }: AssetIssueTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const me = useSelector(selectMe);
  const entity = useSelector(selectAssetIssueByID(assetIssueId));
  const isAssignedToMe = me?.guid && entity && entity.assignedToUserId === me.guid;

  if (!entity) {
    return null;
  }

  const removeAssetIssue = () => {
    openModal(
      <ConfirmModal
        actionText="Remove issue"
        description="Are you sure you want to delete this tool issue? Doing this is permanent."
        onConfirm={async () => {
          try {
            await dispatch(deleteAssetIssue(assetIssueId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Issue has been removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const toggleResolved = async () => {
    try {
      const { dateResolved } = entity;
      await dispatch(
        updateAssetIssue({
          ...entity,
          dateResolved: dateResolved ? undefined : new Date().toISOString(),
          resolvedByUserId: !dateResolved ? me?.guid : entity.resolvedByUserId,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: dateResolved ? "info" : "success",
          title: dateResolved ? "Issue has been re-opened" : "Issue has been resolved",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const toggleAssignment = async () => {
    try {
      if (!me?.guid) return;
      await dispatch(
        updateAssetIssue({
          ...entity,
          assignedToUserId: isAssignedToMe ? undefined : me.guid,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: isAssignedToMe ? "info" : "success",
          title: isAssignedToMe ? "You are no longer assigned to this issue" : "Issue has been assigned to you",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      isAssignedToMe
        ? {
            label: "Unassign from me",
            icon: HiOutlineUserRemove,
            onClick: toggleAssignment,
          }
        : {
            label: "Assign to me",
            icon: HiOutlineUserAdd,
            onClick: toggleAssignment,
          },
      {
        label: entity.dateResolved ? "Re-open issue" : "Resolve issue",
        icon: entity.dateResolved ? HiOutlineFlag : HiOutlineCheckCircle,
        onClick: toggleResolved,
      },
    ],
    [
      {
        label: "Remove issue",
        icon: TrashIcon,
        onClick: removeAssetIssue,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
