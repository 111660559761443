import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { updateAlert } from "lib/actions/alerts";
import { showPopupFlag } from "lib/actions/userInterface";
import AlertRules from "lib/constants/AlertRules";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAlerts, selectAlertsPage } from "store/reducers";
import { FixLater, TAlert, TPaging } from "types";
import { SecondaryButton } from "components/Buttons";
import { useIsAdmin } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { TableLink } from "components/Table";
import { SmartTable, SmartTablePagination } from "components/Table/SmartTable";
import { Text } from "components/Typography";
import { CreateAlertModal } from "components/shared";
import { TrackingAlertsTableRowDropdown } from "components/shared/features/trackingAlerts";
import PageStage from "components/stages/PageStage";

export const ToolTrackingAlertsPage = () => {
  const { pathname: url } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const entities = useSelector(selectAlertsPage);
  const canUpdate = useIsAdmin();
  const [paging, setPaging] = useState<TPaging>({
    pageStart: 0,
    pageLimit: 50,
  });

  const fetch = (pageStart: number, pageLimit: number) => {
    dispatch(
      fetchAlerts({
        pageStart,
        pageLimit,
        rules: [AlertRules.ASSET_ISSUE_CREATED, AlertRules.CALIBRATION_VIOLATION, AlertRules.CALIBRATION_NEAR_DEADLINE],
      }),
    );
  };

  useEffect(() => fetch(paging.pageStart, paging.pageLimit), [paging]);

  const rowData = useMemo(() => entities, [entities]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        Cell: ({ row }: FixLater) => {
          const { id, name }: TAlert = row.original;
          return (
            <TableLink to={`${url}/${id}`}>
              <Text
                canEdit={canUpdate}
                placeholder="Enter alert name"
                value={name}
                onConfirm={(value) =>
                  saveChanges({
                    ...row.original,
                    name: value,
                  })
                }
              />
            </TableLink>
          );
        },
      },
      {
        Header: "Description",
        Cell: ({ row }: FixLater) => {
          const { description }: TAlert = row.original;
          return (
            <Text
              canEdit={canUpdate}
              placeholder="Enter description"
              value={description}
              onConfirm={(value) =>
                saveChanges({
                  ...row.original,
                  description: value,
                })
              }
            />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: FixLater) => {
          const { id }: TAlert = row.original;
          return (
            <TrackingAlertsTableRowDropdown
              alertId={id}
              onDelete={() => {
                setPaging(({ pageLimit }) => ({
                  pageStart: 0,
                  pageLimit,
                }));
              }}
            />
          );
        },
      },
    ];
  }, []);

  const handleCreate = () => {
    openModal(
      <CreateAlertModal
        onSuccess={(alert: TAlert) => {
          navigate(`${url}/${alert.id}`);
        }}
      />,
    );
  };

  const saveChanges = (alert: TAlert) => {
    dispatch(
      updateAlert(
        alert,
        () => {
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Changes saved",
            }),
          );
        },
        (err: Error) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Tool tracking alerts"
        subtitle="Configure and manage tool tracking alerts"
        action={
          <SecondaryButton onClick={handleCreate} icon="/images/icons/plus_1.svg">
            Create alert
          </SecondaryButton>
        }
      />
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination
        paging={paging}
        onChange={(pageStart: number, pageLimit: number) => {
          setPaging({
            pageStart,
            pageLimit,
          });
        }}
      />
    </PageStage>
  );
};
