import styled, { css } from "styled-components";
import colors from "tailwindcss/colors";

const DrawerAppearance = css`
  color: ${colors.slate["200"]};

  &:hover {
    color: ${colors.slate["200"]};
  }
`;

const AllocatedTool = css`
  color: ${colors.slate["900"]};
  cursor: not-allowed;

  &:hover {
    color: ${colors.slate["900"]};
  }
`;

export enum ToolSVGAppearance {
  Tool = "tool",
  Drawer = "drawer",
}

const ToolPath = styled.path<Omit<ToolSVGProps, "path">>`
  fill: currentColor;
  transition: fill 100ms ease-in-out;
  stroke-width: 1;
  stroke-opacity: 1;

  ${({ appearance }) => appearance === ToolSVGAppearance.Drawer && DrawerAppearance}
  ${({ appearance, selected }) =>
    appearance === ToolSVGAppearance.Tool &&
    css`
      cursor: pointer;
      color: ${selected ? colors.blue["500"] : colors.slate["50"]};

      &:hover {
        color: ${selected ? colors.blue["500"] : colors.slate["50"]};
      }
    `}
  ${({ located }) => located && AllocatedTool}
  ${({ pulse }) =>
    pulse &&
    css`
      animation: tool-pulse 1s infinite;
      -webkit-transform-origin: center center;
      opacity: 1;
    `}
`;

type ToolSVGProps = {
  path: string;
  appearance?: ToolSVGAppearance;
  selected?: boolean;
  located?: boolean;
  pulse?: boolean;
  onClick?: () => void;
};

export const ToolSVG = ({
  path,
  appearance,
  selected = false,
  located = false,
  pulse = false,
  onClick,
}: ToolSVGProps) => {
  const handleClick = () => {
    if (!located) {
      onClick && onClick();
    }
  };

  return (
    <ToolPath
      appearance={appearance}
      pulse={pulse}
      selected={selected}
      located={located}
      onClick={handleClick}
      d={path}
      style={{
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        stroke: "transparent",
      }}
    />
  );
};
