import { useModal } from "effects";
import { DangerButton } from "components/Buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";

type DoorwayRemoveButtonProps = {
  onClick: () => void;
};

export const DoorwayRemoveButton = ({ onClick }: DoorwayRemoveButtonProps) => {
  const { openModal } = useModal();

  const handleRemove = () => {
    openModal(
      <ConfirmModal
        actionText="Remove doorway"
        description="Are you sure you want to delete this doorway? Doing this is permanent."
        onConfirm={onClick}
      />,
    );
  };

  return <DangerButton onClick={handleRemove}>Remove doorway</DangerButton>;
};
