import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteRole, fetchRoleById, selectRoleByID, updateRole } from "store/reducers";
import { FixLater, UpdateRoleRequest } from "types";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { Header, Paragraph } from "components/Typography";
import { PowerInput } from "components/inputs/PowerInput";
import { PageLoading, RoleCapabilities, VSpace } from "components/shared";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import PageStage from "components/stages/PageStage";

export const ListRolePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const roleId = parseInt(params.roleID as string);
  const { openModal } = useModal();
  const data = useSelector(selectRoleByID(roleId));

  useEffect(() => {
    if (!data) {
      dispatch(fetchRoleById(roleId));
    }
  }, [roleId, data]);

  const saveChanges = (name: string) => {
    return async (value: FixLater) => {
      try {
        await dispatch(
          updateRole({
            ...data,
            [name]: value,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Role updated",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const removeRole = () => {
    openModal(
      <ConfirmModal
        actionText="Remove role"
        description="Are you sure you want to delete this role? The role must not be assigned to any team members."
        onConfirm={async () => {
          try {
            await dispatch(deleteRole(roleId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Role was removed",
              }),
            );
            navigate("/configuration/roles", { replace: true });
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const toggleIsAdmin = async () => {
    try {
      if (!data) return;
      console.log("data.isAdmin", data.isAdmin);
      const requestBody: UpdateRoleRequest = {
        ...data,
        isAdmin: !data.isAdmin,
      };
      await dispatch(updateRole(requestBody)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!data) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <Header canEdit={true} placeholder="Enter role name" value={data.name} onConfirm={saveChanges("name")} />
      <Paragraph
        canEdit={true}
        size="large"
        placeholder="Enter role description"
        value={data.description}
        onConfirm={saveChanges("description")}
      />
      <VSpace>
        <DataGrid>
          <DataItem
            label="Administrator"
            description="Enable all functionality"
            value={<PowerInput value={data.isAdmin} onChange={toggleIsAdmin} />}
          />
        </DataGrid>
        {!data.isAdmin && <RoleCapabilities roleId={roleId} onChange={saveChanges("capabilities")} />}
        <DataGrid>
          <DataItem label="Danger zone" value={<DangerButton onClick={removeRole}>Remove role</DangerButton>} />
        </DataGrid>
      </VSpace>
    </PageStage>
  );
};
